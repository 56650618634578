import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { APIResponse } from '../../@types/api'
import api from '../../helpers/api'
import { format } from '../../helpers/number'
import { requestUser } from '../../store/user/user.actions'
import { Button, Typography } from 'antd'

const { Text } = Typography

interface CashbackWidgetProps {
	cashback: number
}
const CashbackWidget: FC<CashbackWidgetProps> = ({ cashback }) => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const acceptCashback = async () => {
		setLoading(true)
		try {
			const res = await api.post<void, APIResponse>('/member/cashback')

			dispatch(requestUser())
			toast.success(res.message)
		} catch ({ message }: any) {
			toast.error(message as string)
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className="wallet-other-card balance-other-item">
			<div className="wallet-other-card-inner">
				<Text type="secondary">ยอดแคชแบ็ก</Text>
				<div className="wallet-other-card-amount">฿ {format(cashback)}</div>
			</div>
			<Button
				type="primary"
				className="ant-btn-secondary"
				loading={loading}
				onClick={acceptCashback}
			>
				<span>รับแคชแบ็ก</span>
			</Button>
		</div>
	)
}

export default CashbackWidget
