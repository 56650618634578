import React, { ChangeEvent, FC } from 'react'
import { Promotion } from '../../@types/prefix'
import { Button, Form, Input, Select } from 'antd'
// import { RedoOutlined } from '@ant-design/icons';
// import { getDomainWithoutSubdomain } from '../../utils/domainWithoutSubdomain'
import Turnstile from "react-turnstile";

interface RegisterStep3Props {
	knowFrom: string
	defaultAffiliateCode: string
	promotions: Promotion[]
	isSubmitting: boolean
	recaptcha: any
	resetCounter: number

	setKnowFrom: (e: any) => void
	handleSubmit: (e: ChangeEvent<any>) => void
	setTurnstile: (e: any) => void
}

const RegisterStep3: FC<RegisterStep3Props> = ({
	knowFrom,
	defaultAffiliateCode,
	promotions,
	isSubmitting,
	recaptcha,
	resetCounter,

	setKnowFrom,
	handleSubmit,
	setTurnstile
}) => {
	const affiliateReadOnly = defaultAffiliateCode ? true : false

	return (
		<>
			<Form.Item
				name="password"
				label="รหัสผ่าน"
				rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน' }]}
				hasFeedback>
				<Input.Password placeholder="รหัสผ่าน" inputMode='text' />
			</Form.Item>
			<Form.Item
				name="line"
				label="ไอดีไลน์"
				rules={[{ required: true, message: 'กรุณากรอกไอดีไลน์' }]}
				hasFeedback>
				<Input placeholder="ไอดีไลน์" inputMode='text' />
			</Form.Item>
			<Form.Item
				name="autoBonus"
				label="รับโบนัสอัตโนมัติ"
				rules={[{ required: true, message: 'กรุณาเลือกรับโบนัสอัตโนมัติ' }]}
				hasFeedback
				initialValue={"off"}>
				<Select
					placeholder="เลือกรับโบนัสอัตโนมัติ"
					showSearch
					optionFilterProp="children"
					filterOption={(input: any, option: any) =>
						option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}>
					<Select.Option value="off">ไม่รับโบนัสอัตโนมัติ</Select.Option>
					{promotions
						.filter((v) => v.amount > 0)
						.map((promotion) => (
							<Select.Option value={promotion.key} key={promotion.key}>
								{promotion.name}
							</Select.Option>
						))}
				</Select>
			</Form.Item>
			{!defaultAffiliateCode && (
				<Form.Item
					name="knowFrom"
					label="ช่องทางที่รู้จักเรา"
					rules={[{ required: true, message: 'กรุณาเลือกช่องทางที่รู้จักเรา' }]}
					hasFeedback>
					<Select
						placeholder="เลือกช่องทางที่รู้จักเรา ..."
						showSearch
						optionFilterProp="children"
						onSelect={setKnowFrom}
						filterOption={(input: any, option: any) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}>
						<Select.Option value="facebook" key="facebook">Facebook</Select.Option>
						<Select.Option value="twitter" key="twitter">Twitter</Select.Option>
						<Select.Option value="line" key="line">Line</Select.Option>
						<Select.Option value="google" key="google">Google</Select.Option>
						<Select.Option value="instargram" key="instargram">Instargram</Select.Option>
						<Select.Option value="youtube" key="youtube">Youtube</Select.Option>
						<Select.Option value="tiktok" key="tiktok">Tiktok</Select.Option>
						<Select.Option value="onlyfans" key="onlyfans">Onlyfans</Select.Option>
						<Select.Option value="telegram" key="telegram">Telegram</Select.Option>
						<Select.Option value="vk" key="vk">VK</Select.Option>
						<Select.Option value="friend" key="friend">เพื่อนแนะนำ</Select.Option>
						<Select.Option value="other" key="other">โฆษณาอื่น ๆ</Select.Option>
					</Select>
				</Form.Item>
			)}
			{defaultAffiliateCode && (
				<Form.Item
					name="knowFrom2"
					label="ช่องทางที่รู้จักเรา"
					rules={[{ required: true, message: 'กรุณาเลือกช่องทางที่รู้จักเรา' }]}
					hasFeedback>
					<Select
						placeholder="เลือกช่องทางที่รู้จักเรา ..."
						showSearch
						optionFilterProp="children"
						filterOption={(input: any, option: any) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}>
						<Select.Option value="facebook" key="facebook">Facebook</Select.Option>
						<Select.Option value="twitter" key="twitter">Twitter</Select.Option>
						<Select.Option value="line" key="line">Line</Select.Option>
						<Select.Option value="google" key="google">Google</Select.Option>
						<Select.Option value="instargram" key="instargram">Instargram</Select.Option>
						<Select.Option value="youtube" key="youtube">Youtube</Select.Option>
						<Select.Option value="tiktok" key="tiktok">Tiktok</Select.Option>
						<Select.Option value="onlyfans" key="onlyfans">Onlyfans</Select.Option>
						<Select.Option value="telegram" key="telegram">Telegram</Select.Option>
						<Select.Option value="vk" key="vk">VK</Select.Option>
						<Select.Option value="other" key="other">โฆษณาอื่น ๆ</Select.Option>
					</Select>
				</Form.Item>
			)}

			{knowFrom === "friend" && (
				<Form.Item
					name="affiliateCode"
					label="รหัสชวนเพื่อน"
					rules={[{ required: true, message: 'กรุณากรอกรหัสชวนเพื่อน' }]}
					hasFeedback
					initialValue={defaultAffiliateCode}>
					<Input placeholder="รหัสชวนเพื่อน" inputMode='text' readOnly={affiliateReadOnly} />
				</Form.Item>
			)}

			{recaptcha.enable && (
				<div style={{ marginBottom: "10px" }}>
					<Turnstile
						key={resetCounter}
						sitekey={recaptcha.key}
						theme="dark"
						onVerify={(token) => setTurnstile(token)}
						retry='auto'
						autoResetOnExpire={true}
					/>
				</div>
			)}

			<Form.Item>
				<Button
					type="primary"
					htmlType="submit"
					loading={isSubmitting}
					onClick={handleSubmit}
					block
				>
					สมัครสมาชิก
				</Button>
			</Form.Item>

		</>
	)
}

export default RegisterStep3
