import React, { FC, useEffect, useState } from 'react'
import { Avatar, Tag } from 'antd'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { getDomainWithoutSubdomain } from '../../utils/domainWithoutSubdomain'

interface Props {
    prefixState: any
}

const Footer: FC<Props> = ({ prefixState }) => {
    const [tags, setTags] = useState<string[]>([])
    const [copyright, setCopyright] = useState<string>()
    const YearNow = dayjs().format('YYYY')

    useEffect(() => {
        setTags(prefixState.info?.seo.tags || [])
        setCopyright(`Copyright © ${YearNow} All Right Reserved.`)
        if (window.location.hostname !== 'localhost') {
            const nameUrl = getDomainWithoutSubdomain(window.location.host).split('.')[0]
            setCopyright(`Copyright © ${YearNow} ${nameUrl} All Right Reserved.`)
        }
    }, [prefixState.info?.seo.tags])//eslint-disable-line

    return (
        <div className="footer">
            <div className="footer-info">
                {prefixState.info?.logo && (
                    <Link to="/wallet">
                        <Avatar
                            className="avatar-logo"
                            src={prefixState.info?.logo}
                            shape="square"
                            size={80}
                            style={{ marginBottom: 16 }} />
                    </Link>
                )}

                {prefixState.info?.seo?.title ?
                    <h1>{prefixState.info?.seo?.title}</h1> :
                    <h1>{prefixState.info?.title} คาสิโนออนไลน์ สล็อตออนไลน์ ที่ดีที่สุด</h1>
                }

                {prefixState.info?.seo?.description ?
                    <p>{prefixState.info?.seo?.description}</p> :
                    <p>{prefixState.info?.title} บริการทุกระดับประทับใจ คาสิโนออนไลน์ สล็อตออนไลน์ ที่ดีที่สุด เพื่อประสบการณ์ที่ดีของผู้เล่นอย่างแท้จริง</p>
                }
            </div>
            <div className="footer-tags">
                {tags.map((tag, index) => (
                    <a href={`https://${window.location.hostname}`} rel="dofollow" key={index}>
                        <Tag>{tag}</Tag>
                    </a>
                ))}
            </div>
            <div className="footer-partners">
                <img src="/provider-icon/wm.png" alt="wm" />
                <img src="/provider-icon/sa.png" alt="sa" />
                <img src="/provider-icon/allbet.png" alt="allbet" />
                <img src="/provider-icon/sexy.png" alt="sexy" />
                <img src="/provider-icon/ae.png" alt="ae" />
                <img src="/provider-icon/dream.png" alt="dream" />
                <img src="/provider-icon/mglive.png" alt="mglive" />
                <img src="/provider-icon/pretty.png" alt="pretty" />
                <img src="/provider-icon/n2live.png" alt="" />
                <img src="/provider-icon/bg.png" alt="bg" />
                <img src="/provider-icon/entwine.png" alt="entwine" />
                <img src="/provider-icon/evolution.png" alt="evolution" />
                <img src="/provider-icon/xg.png" alt="xg" />
                <img src="/provider-icon/playtechlive.png" alt="playtechlive" />
                <img src="/provider-icon/pragmatic.png" alt="pragmatic" />
                <img src="/provider-icon/ebet.png" alt="ebet" />
            </div>
            <div className="footer-info" style={{ marginTop: '20px' }}>
                <p>{copyright}</p>
            </div>
        </div>
    )
}

export default Footer
