import React, { FC } from 'react'
import { format } from '../../helpers/number'
import { Typography } from 'antd'

const { Text } = Typography

interface TurnWidgetProps {
	isTurnover: boolean
	credit: number
	totalTurn: number
	currentTurnover: number
}

const TurnWidget: FC<TurnWidgetProps> = ({ isTurnover, credit, totalTurn, currentTurnover }) => {
	return (
		<div className="wallet-other-card balance-other-item">
			<div className='wallet-other-card-inner'>
				<Text type="secondary">
					{isTurnover ? 'ยอดเทิร์นโอเวอร์' : 'ยอดเทิร์น'}
				</Text>
				<div className="wallet-other-card-amount">
					<span>฿ {format(isTurnover ? currentTurnover : totalTurn)}</span>
					<span className="wallet-other-card-totalturn">/ {format(totalTurn)}</span>
				</div>
			</div>
		</div>
	)
}

export default TurnWidget
