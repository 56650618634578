import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import api from '../../helpers/api'
import { APIResponse } from '../../@types/api'
import { ApplicationState } from '../../store'
import { requestUser } from '../../store/user/user.actions'

const PromotionSlides = () => {
    const dispatch = useDispatch()
    const prefixState = useSelector((state: ApplicationState) => state.prefix)
    const userState = useSelector((state: ApplicationState) => state.user)
    const prefix = prefixState.info, user = userState.info
    const filteredPromotions = prefix && prefix.promotions.filter(promotion => promotion.amount > 0).length;
    const promotionRef = useRef<HTMLDivElement>(null);
    const [autoBonusValue, setAutoBonusValue] = useState(user?.autoBonus)

    const changeAutoBonus = async (promotionKey: string) => {
        try {
            const res = await api.patch<void, APIResponse>(
                `/member/promotions/${promotionKey}`
            )

            dispatch(requestUser())
            setAutoBonusValue(promotionKey)
            // toast.success(res.message)
        } catch ({ message }: any) {
            toast.error(message as string)
        }
    }

    useEffect(() => {
        if (promotionRef.current) {
            const container = promotionRef.current;

            const activeItem = document.querySelector('.promotion-slider-item.active');

            if (activeItem) {
                const containerRect = container.getBoundingClientRect();
                const activeItemRect = activeItem.getBoundingClientRect();

                const offsetLeft = activeItemRect.left - containerRect.left - (containerRect.width - activeItemRect.width) / 2;

                container.scrollTo({
                    left: offsetLeft,
                    behavior: 'smooth',
                });
            }
        }
    }, []);

    return (
        <>
            {
                (filteredPromotions ?? 0) > 0 && (
                    <div ref={promotionRef} className={`promotion-slider-container ${(filteredPromotions ?? 0) < 10 ? 'center' : ''}`}>
                        <div className={`promotion-slider-item ${autoBonusValue === 'off' ? 'active' : ''}`} onClick={() => changeAutoBonus('off')}>
                            <div className="promotion-slider-item-title">ไม่รับโบนัส</div>

                            {autoBonusValue === 'off' && (
                                <div className="promotion-slider-item-checked">
                                    <span className="material-symbols-rounded">
                                        done
                                    </span>
                                </div>
                            )}
                        </div>
                        {prefix && prefix.promotions.map((item: any) => (
                            item.amount > 0 && (
                                <div
                                    key={item.key}
                                    className={`promotion-slider-item ${autoBonusValue === item.key ? 'active' : ''}`}
                                    onClick={() => changeAutoBonus(item.key)}
                                >
                                    <img src="/icons/new/promotion.svg" alt="" />
                                    <div className="promotion-slider-item-title">{item.name}</div>

                                    {autoBonusValue === item.key && (
                                        <div className="promotion-slider-item-checked">
                                            <span className="material-symbols-rounded">
                                                done
                                            </span>
                                        </div>
                                    )}

                                </div>
                            )
                        ))}
                    </div>
                )
            }
        </>
    )
}

export default PromotionSlides
