import React, { FC, useState } from 'react'
// import Skeleton from './SkeletonContainer';
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";

interface MainSliderProps {
    prefixState: any
}

const MainSlider: FC<MainSliderProps> = ({ prefixState }) => {
    const [loading, setLoading] = useState(true);
    const topgraphicsStatus = prefixState.info.topgraphics.enable
    const topgraphicsItems = prefixState.info.topgraphics.items ? prefixState.info.topgraphics.items.sort((a: any, b: any) => a.order - b.order) : prefixState.info.topgraphics.items

    const handleImageLoad = () => {
        setLoading(false);
    };

    return (
        topgraphicsStatus && topgraphicsItems ? (
            <Carousel
                autoPlay={true}
                showStatus={false}
                showThumbs={false}
                interval={5000}
                infiniteLoop={true}
                className="main-slider-container"
            >
                {topgraphicsItems.map((item: any, key: any) => {
                    return (
                        <div className="main-slide" key={key}>
                            {loading && <div className="skeleton-container" />}
                            <img src={item.image} alt="" onLoad={handleImageLoad} style={{ display: loading ? 'none' : 'block' }} />
                        </div>
                    )
                })}
            </Carousel>
        ) : (
            <></>
        )

    )
}

export default MainSlider
