import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, matchPath } from 'react-router'
import { ApplicationState } from '../../store'
import BottomBar from './BottomBar'
import Sidebar from './Sidebar'
import Topbar from './Topbar'
import TopbarMobile from './TopbarMobile'
import { AskmebetConfig } from '../../entities/askmebet'
import api from '../../helpers/api'
import SetTheme from '../../utils/SetTheme'
import { toast } from 'react-toastify'
import MainAudio from '../Layout/MainAudio'
import TopbarWidget from '../Layout/TopbarWidget'
import { Avatar } from 'antd'
import { Link } from 'react-router-dom'
import { makePath } from '../../helpers/path'
import ButtonFooter from './ButtonFooter'

const AppLayout: FC = ({ children }) => {
	const elGame = useRef<HTMLDivElement>(null)
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const userState = useSelector((state: ApplicationState) => state.user)
	const loc = useLocation()

	const matchSlotPath: any = matchPath(loc.pathname, {
		path: '/slot/:providerID',
		exact: true,
		strict: true
	})

	// sound
	const [isSound, setIsSound] = useState<boolean>(false)
	const setIsToggleSound = () => {
		const getLocalSound = localStorage.getItem('sound')
		if (getLocalSound === 'On') {
			setIsSound(true)
		} else {
			setIsSound(false)
		}
	}

	// Get provider name
	const [config, setConfig] = useState<AskmebetConfig | undefined>(undefined) // eslint-disable-line
	const initAskmebetConfig = useCallback(async () => {
		try {
			const res = await api.get<void, AskmebetConfig>('/askmebet')

			setConfig(res)
		} catch ({ message }: any) {
			toast.error(message as string)
		}
	}, [])

	useEffect(() => {
		if (matchSlotPath?.isExact) {
			initAskmebetConfig()
		}
	}, [initAskmebetConfig]) // eslint-disable-line

	const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)

	useEffect(() => {
		if (prefixState.info) {
			const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
				if (value === "") {
					return true;
				}
				return false;
			}) : true;
			setShowMenuSupport(isNullishSocialMedia)
		}
	}, [prefixState])

	return (
		<div className="wrapper">
			<MainAudio prefixState={prefixState.info} isSound={isSound} setIsToggleSound={() => { setIsToggleSound() }} elGame={elGame} />
			<SetTheme />
			<Sidebar
				prefix={prefixState.info}
				member={userState.info}
				showMenuSupport={showMenuSupport}
				isSound={isSound}
				setIsToggleSound={() => { setIsToggleSound() }}
			/>
			<div className="content" ref={elGame}>
				<div className="layout-header">
					<Topbar
						prefix={prefixState.info}
						member={userState.info}
						pathname={loc.pathname}
						setIsToggleSound={() => { setIsToggleSound() }}
					/>

					{loc.pathname !== '/wallet' && loc.pathname !== '/launch-game' && (
						<TopbarMobile pathname={loc.pathname} prefix={prefixState.info} member={userState.info} />
					)}

					{loc.pathname === '/launch-game' && (
						<div className="topbar-mobile no-border" style={{ paddingLeft: 12, paddingRight: 12, zIndex: 999 }}>
							<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
								<div className="topbar-logo">
									<Link to={makePath('/wallet')}>
										{prefixState?.info?.logo ? (
											<Avatar
												className="avatar-logo"
												src={prefixState?.info?.logo}
												shape="square"
											/>
										) : (
											<div className="prefix-name">{prefixState?.info?.prefix}</div>
										)}
									</Link>
								</div>
								<TopbarWidget prefix={prefixState.info} member={userState.info} setIsToggleSound={setIsToggleSound} />
							</div>
						</div>
					)}
				</div>
				<div className="web-container">
					{children}
				</div>
				<ButtonFooter prefixState={prefixState} setIsToggleSound={setIsToggleSound} />
				<BottomBar member={userState.info} />
			</div>
		</div>
	)
}

export default AppLayout
