import { Modal } from 'antd'
import React, { FC } from 'react'
// import { useSelector } from 'react-redux'
// import { ApplicationState } from '../../store'
import 'react-lazy-load-image-component/src/effects/blur.css';

import Affiliate from '../../views/Affiliate'
import Commission from '../../views/Commission'
// import AMBPay from '../../views/AMBPay'
// import BankDeposit from '../../views/BankDeposit'
import Deposit from '../../views/Deposit'
// import Games from '../../views/Games'
// import LaunchGame from '../../views/LaunchGame'
// import Logout from '../../views/Logout'
import LuckyWheel from '../../views/LuckyWheel'
import Profile from '../../views/Profile'
import Account from '../../views/Account'
import Promotion from '../../views/Promotion'
// import Support from '../../views/Support'
import Transactions from '../../views/Transactions'
// import Wallet from '../../views/Wallet'
// import WalletDeposit from '../../views/WalletDeposit'
import Withdraw from '../../views/Withdraw'
// import UploadSlip from '../../views/UploadSlip'
import Ranking from '../../views/Ranking'

interface ModalComponentsProps {
    title: string
    keyModal: string
    isOpen: boolean
    onClose: () => void
}
const ModalComponents: FC<ModalComponentsProps> = ({ keyModal, title, isOpen, onClose }) => {


    return (
        <Modal
            className={(keyModal === "promotion" || keyModal === "transactions" || keyModal === "ranking") ? 'ant-modal-transactions' : 'ant-modal-components'}
            open={isOpen}
            title={title}
            centered
            onCancel={() => onClose()}
            footer={false}
            width={720}
        >
            {keyModal === "deposit" && <Deposit />}
            {keyModal === "withdraw" && <Withdraw />}
            {keyModal === "transactions" && <Transactions />}
            {keyModal === "promotion" && <Promotion />}
            {keyModal === "lucky-wheel" && <LuckyWheel />}
            {keyModal === "affiliate" && <Affiliate />}
            {keyModal === "commission" && <Commission />}
            {keyModal === "profile" && <Profile modal={isOpen} />}
            {keyModal === "account" && <Account />}
            {keyModal === "ranking" && <Ranking />}


        </Modal>
    )
}

export default ModalComponents
