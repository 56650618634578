import React, { FC, useState, useEffect } from 'react';
import { RightOutlined } from '@ant-design/icons';

interface DepositMethodButtonProps {
    icon: string
    title: string
    subTitle?: string
    secondary?: boolean
}

const DepositMethodButton: FC<DepositMethodButtonProps> = ({
    icon,
    title,
    subTitle,
    secondary
}) => {
    // State to store the random animation duration
    const [animationDuration, setAnimationDuration] = useState<number>(0);

    // Function to generate a random duration
    const generateRandomDuration = () => {
        const minDuration = 2000; // Minimum duration in milliseconds
        const maxDuration = 8000; // Maximum duration in milliseconds
        const randomDuration = Math.floor(Math.random() * (maxDuration - minDuration + 1)) + minDuration;
        return randomDuration;
    };

    // Set the random duration when the component mounts
    useEffect(() => {
        const randomDuration = generateRandomDuration();
        setAnimationDuration(randomDuration);
    }, []);

    return (
        <div className={'deposit-method' + (secondary ? ' bg-secondary' : '')}>
            <div className="deposit-method-icon">
                <img src={icon} alt={title} style={{ animation: `dance ${animationDuration}ms linear infinite` }} />
            </div>
            <div className="deposit-method-name">
                <span>{title}</span>

                {subTitle && (
                    <>
                        <br />
                        <small>{subTitle}</small>
                    </>
                )}
            </div>
            <RightOutlined rev={undefined} />
        </div>
    )
}

export default DepositMethodButton
