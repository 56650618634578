import React, { FC } from 'react'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import { topbarText } from '../../constant'
import { makePath } from '../../helpers/path'
import { LeftOutlined } from '@ant-design/icons';
import { Avatar, Typography } from 'antd';
import TopbarWidget from './TopbarWidget';
import { Member } from '../../@types/member';
import { Link } from 'react-router-dom'

const { Text } = Typography

interface TopbarMobileProps {
	pathname: string
	providerName?: string
	prefix: any
	member?: Member
}

const TopbarMobile: FC<TopbarMobileProps> = ({ pathname, providerName, prefix, member }) => {
	const matchSlotPath: any = matchPath(pathname, {
		path: '/slot/:providerID',
		exact: true,
		strict: true
	})

	const history = useHistory()
	const location = useLocation();

	const linkToBack = () => {
		if (location.pathname === '/profile' || location.pathname === '/promotion' || location.pathname === '/deposit') {
			return history.push(makePath('/wallet'))
		}

		return matchSlotPath?.isExact
			// ? history.push(makePath('/launch-game', [`t=${Date.now() + 5000}`]))
			? history.push(makePath('/launch-game'))
			: history.goBack()
	}

	return (
		<div className="topbar-mobile" style={{ justifyContent: 'space-between' }}>
			{pathname !== '/launch-game' && (
				<div className="topbar-logo">
					<Link to={makePath('/wallet')}>
						{prefix?.logo ? (
							<Avatar
								className="avatar-logo"
								src={prefix?.logo}
								shape="square"
							/>
						) : (
							<div className="prefix-name">{prefix?.prefix}</div>
						)}
					</Link>
				</div>
			)}
			<div className="topbar-mobile-title">
				<Text>
					{providerName
						? providerName
						: (topbarText[pathname] ?? '-')
					}
				</Text>
			</div>
			<TopbarWidget prefix={prefix} member={member} />
		</div>
	)
}

export default TopbarMobile
