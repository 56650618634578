// import { useFormik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { APIResponse } from '../../@types/api'
import api from '../../helpers/api'
import { requestUser } from '../../store/user/user.actions'
import { Button, Form, InputNumber } from 'antd'
import { ApplicationState } from '../../store'
import VerifyPhone from '../VerifyPhone/VerifyPhone'

interface WithdrawFormProps {
	initAmount: number
}
const WithdrawForm: FC<WithdrawFormProps> = ({ initAmount }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch()

	const [isSubmitting, setSubmitting] = useState<boolean>(false)

	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const userState = useSelector((state: ApplicationState) => state.user)

	const [openVerifyPhone, setOpenVerifyPhone] = useState<boolean>(false)

	const handleSubmit = async () => {
		form.validateFields()
			.then(async (values: any) => {
				if (userState.info && prefixState.info) {
					if (!userState.info.phoneVerified && prefixState.info.functions.otp_withdraw) {
						setOpenVerifyPhone(true)
					} else {
						setSubmitting(true)
						try {
							const res = await api.post<void, APIResponse>(
								'/member/withdraw',
								values
							)

							dispatch(requestUser())
							toast.success(res.message)
						} catch (error: any) {
							toast.error(error.message as string)
						} finally {
							setSubmitting(false)
						}
					}
				}

			}).catch((errorInfo) => { });
	}

	useEffect(() => {
		if (form) {
			form.setFieldsValue({
				'amount': initAmount
			})
		}
	}, [form]) //eslint-disable-line

	return (
		<>
			<Form
				form={form}
				name="withdrawForm"
				layout="vertical"
				hideRequiredMark>
				<Form.Item
					name="amount"
					label="จำนวนเงินที่ถอน"
					rules={[{ required: true, message: 'กรุณากรอกจำนวนเงินที่ถอน' }]}
				>
					<InputNumber
						placeholder="จำนวนเงินที่ถอน"
						inputMode="numeric"
						prefix="฿"
						controls={false}
						formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
						style={{ width: '100%' }}
					/>
				</Form.Item>
				<div className="btn-submit">
					<Button type="primary" htmlType="submit" loading={isSubmitting} disabled={isSubmitting} onClick={handleSubmit} block>
						ยืนยันถอนเงิน
					</Button>
				</div>
			</Form>

			{/* Verify Phone */}
			{openVerifyPhone && (
				<VerifyPhone
					phone={userState.info?.phone || ''}
					isOpen={openVerifyPhone}
					prefixInfo={prefixState.info}
					onClose={() => setOpenVerifyPhone(false)} />
			)}
		</>
	)
}

export default WithdrawForm
