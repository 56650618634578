import React, { useEffect, useState } from 'react'
import { PatternFormat } from 'react-number-format';
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { makePath } from '../helpers/path'
import AuthService from '../services/auth'
import { setUser } from '../store/user/user.actions'
import { Button, Checkbox, Flex, Form, Input } from 'antd'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import ModalSupport from '../components/Modal/ModalSupport';
import ForgotPassword from '../components/Login/ForgotPassword';
import { Space, Typography } from 'antd';

// import Cookies from 'js-cookie';

const { Text } = Typography;

const Login = () => {
	const [form] = Form.useForm();
	const [isSubmitting, setSubmitting] = useState<boolean>(false)
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const prefix = prefixState.info
	const [showMenuSupportLine, setShowMenuSupportLine] = useState<boolean>(false)

	// const [username, setUsername] = useState('');
	// const [password, setPassword] = useState('');
	// const [rememberMe, setRememberMe] = useState(false);

	const [openSupport, setOpenSupport] = useState<boolean>(false)
	const handleModalSupport = () => setOpenSupport(!openSupport)

	const [forgotPassword, setForgotPassword] = useState<boolean>(false)
	const handleForgotPassword = () => setForgotPassword(!forgotPassword)

	const dispatch = useDispatch()
	const history = useHistory()

	const handleSubmit = async () => {
		form.validateFields()
			.then(async (values: any) => {
				setSubmitting(true)
				if (values.remember) {
					// const encryptedData = btoa(JSON.stringify({ username, password, rememberMe }));
					const encryptedData = btoa(JSON.stringify({
						'username': values.phone,
						'password': values.password,
						'rememberMe': values.remember
					}));
					localStorage.setItem('rememberedUser', encryptedData);
					// Cookies.set('rememberedUser', encryptedData, { expires: 30 }); // 30 วัน
				} else {
					localStorage.removeItem('rememberedUser');
					// Cookies.remove('rememberedUser');
				}
				delete values.remember
				try {
					const res = await AuthService.login({
						phone: values.phone.replace(/-/g, ''),
						password: values.password,
					})

					dispatch(setUser(res))
					if ((prefix?.game)?.toUpperCase() === "AMBKING") {
						history.push(makePath('/launch-game'))
					} else {
						history.push(makePath('/wallet'))
					}
				} catch ({ message }: any) {
					toast.error(message as string)
					setSubmitting(false)
				} finally {
					setSubmitting(false)
				}
			}).catch();
	}

	const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)

	useEffect(() => {
		if (prefixState.info) {
			const isNullishSocialMediaLine = prefixState.info?.socialMedia ?
				(Object.keys(prefixState.info?.socialMedia).find((item: any) => item === "line") && prefixState.info?.socialMedia.line !== "" ? true : false)
				: false;
			setShowMenuSupportLine(isNullishSocialMediaLine)


			const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
				if (value === "") {
					return true;
				}
				return false;
			}) : true;
			setShowMenuSupport(isNullishSocialMedia)
		}
	}, [prefixState])

	useEffect(() => {
		if (form) {
			form.setFieldsValue({
				'phone': '',
				'password': '',
				'remember': false
			})
			// const rememberedUser = Cookies.get('rememberedUser');
			const rememberedUser = localStorage.getItem('rememberedUser');
			if (rememberedUser !== undefined && rememberedUser !== null) {
				const decodedString = atob(rememberedUser as string);
				if (decodedString) {
					const { username, password, rememberMe } = JSON.parse(decodedString);
					// setRememberMe(rememberMe);
					if (form) {
						form.setFieldsValue({
							'phone': username,
							'password': password,
							'remember': rememberMe
						})
					}
				}
			}
		}
	}, [form])

	return (
		<>
			<div className="form-card">
				<div className="form-card-body" style={{ paddingTop: 32, paddingBottom: 20 }}>
					<Form
						form={form}
						name="truewalletqrdialogForm"
						layout="vertical"
						hideRequiredMark
					>
						<Form.Item
							name="phone"
							label="เบอร์โทรศัพท์"
							rules={[{ required: true, message: 'กรุณากรอกเบอร์โทรศัพท์' }]}
							style={{ marginBottom: 12 }}
						>
							<PatternFormat
								customInput={Input}
								format="###-###-####"
								mask="_"
								type="tel"
								placeholder="เบอร์โทรศัพท์"
							// onChange={(e) => setUsername(e.target.value)}
							/>
						</Form.Item>
						<Form.Item
							name="password"
							label="รหัสผ่าน"
							rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน' }]}
							style={{ marginBottom: 8 }}
						>
							<Input.Password
								placeholder="รหัสผ่าน" inputMode='text'
							// onChange={(e) => setPassword(e.target.value)}
							/>
						</Form.Item>
						<Flex justify='space-between' align='center'>
							<Form.Item
								name="remember"
								valuePropName="checked"
								style={{ marginBottom: 8 }}
							>
								<Checkbox>จดจำผู้ใช้งาน</Checkbox>
							</Form.Item>
							<Button type="link" onClick={handleForgotPassword}>
								<span style={{ color: '#fff', textDecoration: 'underline' }}>ลืมรหัสผ่าน?</span>
							</Button>
						</Flex>
						<Button
							type="primary"
							htmlType="submit"
							loading={isSubmitting}
							onClick={handleSubmit}
							style={{ marginBottom: 16 }}
							block
						>
							เข้าสู่ระบบ
						</Button>
						{/* {(window.location.hostname === 'play.shabuslot.bet' || window.location.hostname === 'localhost') && */}
						{prefix?.functions?.lineLogin &&
							<Link to={makePath('/login-line')}>
								<Button className="ant-btn-dark" style={{ marginBottom: 16 }} block>
									<Space size={12}>
										<img src="/icons/new/line-logo.svg" alt="line logo" style={{ width: 22, paddingTop: 1 }} />
										<div>
											<span>เข้าสู่ระบบด้วย LINE</span>
										</div>
									</Space>
								</Button>
							</Link>
						}
						<Link to={makePath('/register')}>
							<div style={{ textAlign: 'center', padding: '8px 0' }}>
								<Text>คุณยังไม่เป็นสมาชิก? <span style={{ color: '#fff', textDecoration: 'underline' }}>สมัครสมาชิก</span></Text>
							</div>
						</Link>
					</Form>

					{!showMenuSupport && (
						<Link to={"#"} onClick={handleModalSupport} className="chat-button">
							<Button className="ant-btn-dark" style={{ color: '' }}>
								<i className="fa-solid fa-circle-info" />
								<span>ติดต่อเจ้าหน้าที่</span>
							</Button>
						</Link>
					)}
					{prefix?.lineLink && showMenuSupport && (
						<Link to={{ pathname: prefix.lineLink }} target="_blank" className="chat-button">
							<Button className="ant-btn-dark">
								<i className="fa-brands fa-line" />
								<span>ติดต่อเจ้าหน้าที่</span>
							</Button>
						</Link>
					)}
				</div>
			</div>

			<ModalSupport isOpen={openSupport} onClose={() => setOpenSupport(false)} />
			<ForgotPassword isOpen={forgotPassword} onClose={() => setForgotPassword(false)} />
		</>
	)
}

export default Login
