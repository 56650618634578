import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, matchPath, Redirect } from 'react-router'
import { ApplicationState } from '../../store'
import BottomBar from '../Home/BottomBar'
import Sidebar from '../Home/Sidebar'
import Topbar from '../Home/Topbar'
import { AskmebetConfig } from '../../entities/askmebet'
import api from '../../helpers/api'
import SetTheme from '../../utils/SetTheme'
import { toast } from 'react-toastify'
import Alert from '../Home/Alert'
import ModalPromotions from '../Home/ModalPromotions'
import ModalSupport from '../Modal/ModalSupport'
import { makePath } from '../../helpers/path'
import { requestUserDone, requestUserFailed } from '../../store/user/user.actions'
import AuthService from '../../services/auth'
import LoadingLayout from '../Layout/LoadingLayout'
import ButtonFooter from './ButtonFooter'
import MainAudio from './MainAudio'

const HomeLayout: FC = ({ children }) => {
	const elGame = useRef<HTMLDivElement>(null)
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const userState = useSelector((state: ApplicationState) => state.user)
	const loc = useLocation()


	const [openAlert, setOpenAlert] = useState<boolean>(false)
	const handleAlert = () => setOpenAlert(!openAlert)

	const [openPromotion, setOpenPromotion] = useState<boolean>(false)
	const handleModalPromotionst = () => setOpenPromotion(!openPromotion)

	const [openSupport, setOpenSupport] = useState<boolean>(false)
	const handleModalSupport = () => setOpenSupport(!openSupport)

	const matchSlotPath: any = matchPath(loc.pathname, {
		path: '/slot/:providerID',
		exact: true,
		strict: true
	})

	// Get provider name
	const [config, setConfig] = useState<AskmebetConfig | undefined>(undefined)
	const initAskmebetConfig = useCallback(async () => {
		try {
			const res = await api.get<void, AskmebetConfig>('/askmebet')

			setConfig(res)
		} catch ({ message }: any) {
			toast.error(message as string)
		}
	}, [])

	useEffect(() => {
		if (matchSlotPath?.isExact) {
			initAskmebetConfig()
		}
	}, [initAskmebetConfig])

	// sound
	const [isSound, setIsSound] = useState<boolean>(false)
	const setIsToggleSound = () => {
		const getLocalSound = localStorage.getItem('sound')
		if (getLocalSound === 'On') {
			setIsSound(true)
		} else {
			setIsSound(false)
		}
	}

	const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)

	useEffect(() => {
		if (prefixState.info) {
			const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
				if (value === "") {
					return true;
				}
				return false;
			}) : true;
			setShowMenuSupport(isNullishSocialMedia)
		}
	}, [prefixState])

	const dispatch = useDispatch()
	const requestAuthState = useCallback(async () => {
		try {
			const res = await AuthService.getMemberInfo()
			dispatch(requestUserDone(res))
		} catch {
			localStorage.removeItem('x-auth')
			dispatch(requestUserFailed())
		}
	}, [dispatch])

	useEffect(() => {
		requestAuthState()
	}, [requestAuthState])

	if (!userState.isLoaded) {
		return (
			<LoadingLayout prefix={prefixState} />
		)
	}

	if (userState.isLoaded && userState.info) {
		return <Redirect to={makePath('/wallet')} />
	}
	return (
		<div className="wrapper">
			<MainAudio prefixState={prefixState.info} isSound={isSound} setIsToggleSound={() => { setIsToggleSound() }} elGame={elGame} />
			<SetTheme />
			<Sidebar
				prefix={prefixState.info}
				member={userState.info}
				showMenuSupport={showMenuSupport}
				alert={handleAlert}
				modalPromotions={handleModalPromotionst}
				modalSupport={handleModalSupport}
			/>
			<div className="content" ref={elGame}>
				<div className="layout-header">
					<Topbar
						prefix={prefixState.info}
						member={userState.info}
						pathname={loc.pathname}
					/>
				</div>
				<div className="web-container">
					{children}
				</div>
				<BottomBar
					prefix={prefixState.info}
					showMenuSupport={showMenuSupport}
					alert={handleAlert}
					modalPromotions={handleModalPromotionst}
					modalSupport={handleModalSupport}
				/>
				<ButtonFooter prefixState={prefixState} setIsToggleSound={setIsToggleSound} />
			</div>

			<Alert
				isOpen={openAlert}
				onClose={() => setOpenAlert(false)} />

			<ModalPromotions
				isOpen={openPromotion}
				onClose={() => setOpenPromotion(false)} />

			<ModalSupport
				isOpen={openSupport}
				onClose={() => setOpenSupport(false)} />
		</div>
	)
}

export default HomeLayout
