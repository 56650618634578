import actionCreatorFactory from 'typescript-fsa'
import { ThunkDispatch } from '../../@types/store'
import api from '../../helpers/api'

const actionCreator = actionCreatorFactory('@@fcn/gamesHome')

export const setGamesHome = actionCreator<any>('setGamesHome')

export const requestGamesHomeDone = actionCreator<any>('requestGamesHomeDone')
export const requestGamesHomeFailed = actionCreator('requestGamesHomeFailed')

export const requestGamesHome = () => async (dispatch: ThunkDispatch) => {
	try {

		const res = await api.get<void, any>('/listgame/ambking')
		return dispatch(requestGamesHomeDone(res))
		// if (game === 'ambking') {
		// } else {
		// 	return dispatch(requestGamesHomeFailed())
		// }
	} catch (e) {
		console.error(e)
		return dispatch(requestGamesHomeFailed())
	}
}