import React, { FC, useEffect, useState } from 'react'
import { APIResponse } from '../../@types/api'
import { toast } from 'react-toastify'
import api from '../../helpers/api'
import { Button, Divider, Form, Input, Modal } from 'antd'


interface ModalChangePasswordProps {
    isOpen: boolean
    onClose: () => void
}


interface dataReq {
    currentPassword: string
    newPassword: string
    confirm?: string
}

const ChangePassword: FC<ModalChangePasswordProps> = ({ isOpen, onClose }) => {
    const [form] = Form.useForm();
    const [isSubmitting, setSubmitting] = useState<boolean>(false)

    const handleSubmit = async () => {
        form.validateFields()
            .then(async (values: dataReq) => {
                setSubmitting(true)
                delete values.confirm
                try {
                    const res = await api.post<void, APIResponse>(`/member/change-password`, values)
                    toast.success(res.message as string)
                    setSubmitting(false)
                    onClose()
                } catch ({ message }: any) {
                    toast.error(message as string)
                    setSubmitting(false)
                }
            }).catch();
    }

    useEffect(() => {
        if (form && isOpen) {
            form.resetFields();
            setSubmitting(false)
        }
    }, [form, isOpen])

    return (
        <Modal
            className=""
            open={isOpen}
            title="เปลี่ยนรหัสผ่าน"
            centered
            onCancel={() => onClose()}
            footer={false}
        >
            <Form
                form={form}
                name="changePasswordForm"
                layout="vertical"
                requiredMark={false}>
                <Form.Item
                    name="currentPassword"
                    label="รหัสผ่านปัจจุบัน"
                    rules={[{ required: true, message: 'กรุณากรอกรหัสผ่านปัจจุบัน' }]}
                    hasFeedback>
                    <Input.Password placeholder="รหัสผ่านปัจจุบัน" inputMode='text' />
                </Form.Item>
                <Divider />
                <Form.Item
                    name="newPassword"
                    label="รหัสผ่านใหม่"
                    rules={[{ required: true, message: 'กรุณากรอกรหัสผ่านใหม่' }]}
                    hasFeedback>
                    <Input.Password placeholder="รหัสผ่านใหม่" inputMode='text' />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label="ยืนยันรหัสผ่านใหม่"
                    dependencies={['newPassword']}
                    hasFeedback
                    rules={[{ required: true, message: 'กรุณายืนยันรหัสผ่านใหม่!' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('รหัสผ่านใหม่ไม่ตรงกัน'));
                        },
                    }),
                    ]}
                >
                    <Input.Password placeholder="ยืนยันรหัสผ่านใหม่" inputMode='text' />
                </Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    style={{ marginBottom: 16 }}
                    block
                >
                    ยืนยัน
                </Button>
            </Form>

        </Modal>
    )
}

export default ChangePassword
