import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
// import { NavLink } from 'react-router-dom'
import BankInfoWidget from '../components/Withdraw/BankInfoWidget'
import { ApplicationState } from '../store'
// import { makePath } from '../helpers/path'
// import { Button, Result, Row } from 'antd'

const Withdraw = () => {
	const userState = useSelector((state: ApplicationState) => state.user)
	const memberInfo = userState.info

	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	// const prefix = prefixState.info

	const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)

	useEffect(() => {
		if (prefixState.info) {
			const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
				if (value === "") {
					return true;
				}
				return false;
			}) : true;
			setShowMenuSupport(isNullishSocialMedia)
		}
	}, [prefixState])

	if (!memberInfo) {
		return <div>Undefined member</div>
	}

	return (
		<div>
			<BankInfoWidget credit={userState.credit} info={memberInfo} />
			{/* {memberInfo.bankCode !== "TRUEWALLET" ? (
				<BankInfoWidget credit={userState.credit} info={memberInfo} />
			) : (
				<Row justify="center" align="middle" style={{ height: '100%' }}>
					<Result
						status="warning"
						title="ระบบไม่สามารถถอนเงินให้กับบัญชีทรูมันนี่วอลเล็ท"
						extra={[
							!showMenuSupport && (
								<NavLink to={makePath('/support')} className="link-contact">
									<Button type="primary" shape="round">แจ้งปัญหา</Button>
								</NavLink>
							),
							prefix?.lineLink && showMenuSupport && (
								<a href={prefix.lineLink} target="_blank" rel="noreferrer" className="link-contact">
									<Button type="primary" shape="round">แจ้งปัญหา</Button>
								</a>
							)
						]}
					/>
				</Row>
			)} */}
		</div>
	)
}

export default Withdraw
