import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import depositReducer, { defaultState as depositDefaultState, State as DepositState } from './deposit/deposit.reducer'
import prefixReducer, { defaultState as prefixDefaultState, State as PrefixState } from './prefix/prefix.reducer'
import userReducer, { defaultState as userDefaultState, State as UserState } from './user/user.reducer'
import providerReducer, { defaultState as providerDefaultState, State as ProviderState } from './provider/provider.reducer'
import GamesHomeReducer, { defaultState as gamesHomeDefaultState, State as GamesHomeState } from './gamesHome/gamesHome.reducer'

const reduxDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__

export interface ApplicationState {
	user: UserState
	prefix: PrefixState
	deposit: DepositState
	provider: ProviderState
	gamesHome: GamesHomeState
}

export const defaultState: ApplicationState = {
	user: userDefaultState,
	prefix: prefixDefaultState,
	deposit: depositDefaultState,
	provider: providerDefaultState,
	gamesHome: gamesHomeDefaultState
}

const reducers = combineReducers({
	user: userReducer,
	prefix: prefixReducer,
	deposit: depositReducer,
	provider: providerReducer,
	gamesHome: GamesHomeReducer,
})

const enhancers = compose(
	applyMiddleware(thunk),
	process.env.NODE_ENV !== 'production' && reduxDevTools ? reduxDevTools() : (f: any) => f
) as any

export default createStore(reducers, defaultState, enhancers)
