import React, { ChangeEvent, FC } from 'react'
import { PatternFormat } from 'react-number-format'

import { Button, Form, Input } from 'antd'
import Turnstile from "react-turnstile";
interface RegisterStep1Props {
	isSubmitting: boolean
	prefixInfo?: any
	resetCounter: number
	handleSubmit: (e: ChangeEvent<any>) => void
	setTokenTurnstileOTP: (e: any) => void
}
const RegisterStep1: FC<RegisterStep1Props> = ({
	isSubmitting,
	handleSubmit,
	prefixInfo,
	resetCounter,
	setTokenTurnstileOTP
}) => (
	<>
		<Form.Item
			name="phone"
			label="เบอร์โทรศัพท์"
			rules={[{ required: true, message: 'กรุณากรอกเบอร์โทรศัพท์' }]}
			hasFeedback
		>
			<PatternFormat
				customInput={Input}
				format="###-###-####"
				mask="_"
				type="tel"
				placeholder="เบอร์โทรศัพท์"
			/>
		</Form.Item>
		{prefixInfo.functions.otp_register !== undefined && (
			(prefixInfo.recaptcha.enable && prefixInfo?.functions.otp_register && (
				<div style={{ marginTop: "10px", marginBottom: "10px" }}>
					<Turnstile
						key={resetCounter}
						sitekey={prefixInfo.recaptcha.key}
						theme="dark"
						onVerify={(token) => setTokenTurnstileOTP(token)}
						retry='auto'
						autoResetOnExpire={true}
					/>
				</div>
			))
		)}
		<Form.Item >
			<Button
				type="primary"
				htmlType="submit"
				loading={isSubmitting}
				onClick={handleSubmit}
				block
			>
				ถัดไป
			</Button>
		</Form.Item>
	</>
)

export default RegisterStep1
