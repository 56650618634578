import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import HomeLayout from '../components/Layout/HomeLayout'
import MainSlider from '../components/Wallet/MainSlider'
import WalletMobileMenu from '../components/Home/WalletMobileMenu'
import WalletPromotionContainer from '../components/Home/WalletPromotionContainer'
import { ApplicationState } from '../store'
import Alert from '../components/Home/Alert'
import ModalPromotions from '../components/Home/ModalPromotions'
import ModalSupport from '../components/Modal/ModalSupport'
import AMBKing from '../components/LaunchGame/AMBKing1'
import Footer from '../components/Layout/Footer'
// import ButtonFooter from '../components/Layout/ButtonFooter'
import { useHistory } from 'react-router-dom'
import { makePath } from '../helpers/path'
import SeoContent from '../components/Layout/SeoContent'

const Home = () => {
    const prefixState = useSelector((state: ApplicationState) => state.prefix)
    const history = useHistory()

    const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)

    const [openAlert, setOpenAlert] = useState<boolean>(false)
    const handleAlert = () => setOpenAlert(!openAlert)

    const [openPromotion, setOpenPromotion] = useState<boolean>(false)
    const handleModalPromotionst = () => setOpenPromotion(!openPromotion)

    const [openSupport, setOpenSupport] = useState<boolean>(false)
    const handleModalSupport = () => setOpenSupport(!openSupport)

    useEffect(() => {
        if (prefixState.info) {
            const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
                if (value === "") {
                    return true;
                }
                return false;
            }) : true;
            setShowMenuSupport(isNullishSocialMedia)

            if (!prefixState?.info?.functions.hasOwnProperty("homePage")) {
                history.push(makePath('/wallet'))
            } else {
                if (!prefixState?.info?.functions['homePage']) {
                    history.push(makePath('/wallet'))
                }
            }
        }
    }, [prefixState])

    return (
        <HomeLayout>
            {(prefixState.info?.game)?.toUpperCase() === "AMBKING" ? (
                <AMBKing />
            ) : (
                <>
                    <MainSlider prefixState={prefixState} />
                    {/* Mobile menu */}
                    <WalletMobileMenu
                        lineLink={prefixState.info?.lineLink || ''}
                        wheel={prefixState.info?.wheel.enable || false}
                        commission={prefixState.info?.commission || false}
                        showMenuSupport={showMenuSupport}
                        ranking={prefixState.info?.functions.rank || false}
                        alert={handleAlert}
                        modalPromotions={handleModalPromotionst}
                        modalSupport={handleModalSupport}
                    />
                    {/* Promotion */}
                    {(prefixState.info?.promotions.length || 0) > 0 && (
                        <WalletPromotionContainer promotions={prefixState.info?.promotions} />
                    )}
                    <SeoContent />
                    <Footer prefixState={prefixState} />
                </>
            )}

            <Alert
                isOpen={openAlert}
                onClose={() => setOpenAlert(false)} />

            <ModalPromotions
                isOpen={openPromotion}
                onClose={() => setOpenPromotion(false)} />

            <ModalSupport
                isOpen={openSupport}
                onClose={() => setOpenSupport(false)} />
        </HomeLayout>
    )
}

export default Home
