import React, { useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";
import { useSelector } from "react-redux";
// import { toast } from "react-toastify";
import AffiliateTransferForm from '../components/Affiliate/AffiliateTransferForm'
import { ApplicationState } from '../store'
import { format } from '../helpers/number'
import { Button, Col, Divider, Input, Row, Statistic, Typography, theme, message } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { hexToHsl } from '../helpers/hexToHsl'

const { Text } = Typography;
const { useToken } = theme;

const Affiliate = () => {
	const { token } = useToken()
	const defalutColorPrimary = `hsl(${hexToHsl(token.colorPrimary).h}, ${hexToHsl(token.colorPrimary).s}%, ${hexToHsl(token.colorPrimary).l + 8}%)`
	const lightColorPrimary = `hsl(${hexToHsl(token.colorPrimary).h}, ${hexToHsl(token.colorPrimary).s}%, ${hexToHsl(token.colorPrimary).l + 30}%)`
	const userState = useSelector((state: ApplicationState) => state.user);

	const [messageApi, contextHolder] = message.useMessage();
	const copyAffiliateLink = () => {
		navigator.clipboard.writeText(userState.affiliate.link);
		// toast.success("คัดลอกลิงก์ชวนเพื่อนสำเร็จ");
		messageApi.open({
			type: 'success',
			content: 'คัดลอกลิงก์ชวนเพื่อนสำเร็จ',
		});
	};

	const qrRef = useRef(null);
	const [qrRender, setQrRender] = useState(false);

	const qrCode = new QRCodeStyling({
		width: 150,
		height: 150,
		data: userState.affiliate.link,
		margin: 0,
		type: "svg",
		qrOptions: {
			typeNumber: 0,
			mode: "Byte",
			errorCorrectionLevel: "Q",
		},
		imageOptions: {
			hideBackgroundDots: true,
			imageSize: 0.4,
			margin: 0,
		},
		dotsOptions: {
			color: "#ffffff",
		},
		backgroundOptions: {
			color: token.colorBgBase,
		},
		cornersSquareOptions: {
			color: "#ffffff",
		},
		cornersDotOptions: {
			color: "#ffffff",
		},
	});

	useEffect(() => {
		if (!qrRender && qrRef.current && userState.info) {
			qrCode.append(qrRef.current!);
			setQrRender(true);
		}
	}, [userState, qrRender]);

	const Aff_Credit = userState.info?.affiliate.balance;

	return (
		<>
			{contextHolder}
			<Row justify="center" className="margin-form-card">
				<Col xs={24} md={16} xl={12}>
					<div className="form-card">
						<div className="form-card-body">
							<div className="invite-qrcode-container" style={{ marginBottom: 16 }}>
								<div className="invite-qrcode" ref={qrRef} />
							</div>

							<div>
								<Text type="secondary" style={{ display: 'block', marginBottom: 8 }}>ลิงก์ชวนเพื่อน</Text>
								<Input.Group
									style={{ display: 'flex' }}
									compact
								>
									<Input
										defaultValue={userState.affiliate.link}
										style={{ background: '#0d0f10', color: '#fff' }}
										disabled
									/>
									<Button
										type='primary'
										icon={<CopyOutlined style={{ fontSize: 18, marginTop: 1 }} rev={undefined} />}
										style={{ height: 'auto' }}
										onClick={copyAffiliateLink}
									>
										<Text style={{ fontSize: 22 }}>คัดลอก</Text>
									</Button>
								</Input.Group>
								<Divider style={{ borderBlockStart: '1px solid rgba(255, 255, 255, 0.1)' }} />
							</div>

							<Row gutter={16} style={{ marginBottom: 16 }}>
								<Col span={12}>
									<Statistic title="ยอดปัจจุบัน" value={format(Aff_Credit || 0)} prefix="฿" />
								</Col>
								<Col span={12}>
									<Statistic title="รหัสชวนเพื่อน" value={userState.affiliate.code} />
								</Col>
							</Row>
							<AffiliateTransferForm initAmount={Aff_Credit || 0} />
						</div>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default Affiliate;
