import React, { ChangeEvent, FC, useEffect, useState, useRef } from 'react'
import { Button, Divider, Form, Input, Typography } from 'antd'
import { toast } from 'react-toastify'
import api from '../../helpers/api'

const { Text } = Typography

interface VerifyResponse {
    ref: string
    status: boolean
}
interface RegisterStepVerifyOTPProps {
    isSubmitting: boolean
    phone: string
    verifyRef: string
    setVerifyRefOTP: (e: any) => void
    setStep: (e: any) => void
    setStepOTP: (e: any) => void
    setOTPForm: (e: any) => void
    handleSubmit: (e: ChangeEvent<any>) => void
}
const RegisterStepVerifyOTP: FC<RegisterStepVerifyOTPProps> = ({
    isSubmitting,
    phone,
    verifyRef,
    setVerifyRefOTP,
    setStep,
    setStepOTP,
    setOTPForm,
    handleSubmit,
}) => {
    const [otp, setOTP] = useState<string[]>(["", "", "", ""]);
    const inputRefs = useRef<Array<Input<any> | null>>([]);
    const OTP_Delay = 180
    const [timer, setTimer] = useState(OTP_Delay);

    const handleOTPInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        const { value } = e.target;
        const newOTP = [...otp];
        newOTP[index] = value;

        setOTP(newOTP);
        setOTPForm(newOTP.join(''))
        if (value === "" && index > 0) {
            inputRefs.current[index - 1]?.focus();
        } else if (value !== "" && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        if (!/^\d+$/.test(keyValue) || event.currentTarget.value.length >= 1) {
            event.preventDefault();
        }
    };

    const handleVerifyRefOTP = async () => {
        setTimer(OTP_Delay);
        try {
            const values = {
                phone: phone,
            }
            const res: VerifyResponse = await api.post('/register/step-1', values)
            if (res.status) {
                setVerifyRefOTP(res.ref)
            } else {
                toast.error('กรุณารอสักครู่แล้วลองใหม่อีกครั้ง')
            }
        } catch ({ message }) {
            toast.error(message as string)
        } finally {

        }
    }

    useEffect(() => {
        const timerId = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer === 1) {
                    clearInterval(timerId);
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);
        return () => clearInterval(timerId);
    }, [timer]);

    useEffect(() => {
        if (phone === '' || verifyRef === '') {
            setStep(1)
            setStepOTP(false)
        }
    }, [phone, verifyRef]) // eslint-disable-line 

    return (
        <>
            <div className="register-title" style={{ textAlign:"center" }}>
                <Text type="secondary">กรอก OTP ที่ส่งไปยัง {phone && phone.replace(/(\d{3})\d{3}(\d{2})(\d{2})/, '$1*****$3')}</Text>
            </div>
            <div className="register-title" style={{ textAlign:"center" }}>
                <Text type="secondary">OTP Ref.{verifyRef}</Text>
            </div>
            <Divider />
            <Form.Item
                name="otp"
                rules={[
                    { required: true, message: "กรุณากรอก OTP" },
                    { len: 4, message: "OTP ต้องกรอกให้ครบ 4 หลัก" },
                ]}
            >
                <Input.Group className="otp-input-group">
                    {otp.map((digit, index) => (
                        <Input
                            key={index}
                            ref={(ref) => (inputRefs.current[index] = ref)}
                            value={digit}
                            maxLength={1}
                            onChange={(e) => handleOTPInputChange(e, index)}
                            onKeyPress={handleKeyPress}
                        />
                    ))}
                </Input.Group>
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    block>
                    ยืนยัน
                </Button>
            </Form.Item>
            <Form.Item style={{ textAlign: 'center' }}>
                {timer <= 0 ? (
                    <Button type="link" className="btn-reset-password" size="small" onClick={handleVerifyRefOTP}>ขอรหัส OTP ใหม่</Button>
                ) : (
                    <Button type="link" className="btn-reset-password" size="small" disabled>ขอรหัส OTP ใหม่ (รอ {timer} วินาที)</Button>
                )}
            </Form.Item>
        </>
    )
}

export default RegisterStepVerifyOTP
