import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import BankDepositItem from '../components/Deposit/BankDepositItem'
import { ApplicationState } from '../store'
import { Modal, Typography } from 'antd'

const { Text } = Typography

interface Props {
	isOpen: boolean
	onClose: () => void
}

const BankDeposit: FC<Props> = ({ isOpen, onClose }) => {
	const depositToList = useSelector((state: ApplicationState) => state.deposit.depositToList)

	const depositToBanks = depositToList.filter((bank) => bank.bank !== 'truewallet')
	if (depositToBanks.length <= 0) {
		// return <Redirect to="/deposit" />
		onClose()
	}

	return (
		<Modal
			className="ant-modal-deposit"
			open={isOpen}
			title="โอนผ่านธนาคาร"
			centered
			onCancel={onClose}
			footer={null}
		>
			<div className="notice-card warning">
				<div className="notcie-card-icon">
					<img src="/icons/new/notice.svg" alt="notice icon" />
				</div>
				<Text className="notice-card-text">
					ระบบจะเติมเงินอัตโนมัติเข้ายูสเซอร์ของท่าน <Text type="warning"><strong>ภายใน 30 วินาที</strong></Text>
				</Text>
			</div>
			<div className="notice-card danger">
				<div className="notcie-card-icon">
					<img src="/icons/new/danger.svg" alt="notice icon" />
				</div>
				<Text className="notice-card-text">
					สามารถโอนเงินเข้าธนาคารโดยใช้ <Text type="danger"><strong>ชื่อบัญชี/เลขบัญชี</strong></Text> ที่สมัครเข้ามาเท่านั้น
				</Text>
			</div>
			{depositToBanks.map((bank) => (
				<BankDepositItem bank={bank} key={bank.accountNumber} />
			))}
		</Modal>
	)
}

export default BankDeposit
