import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import WalletDepositItem from '../components/Deposit/WalletDepositItem'
import { ApplicationState } from '../store'
import { Modal, Typography } from 'antd'

const { Text } = Typography

interface Props {
	isOpen: boolean
	onClose: () => void
}

const WalletDeposit: FC<Props> = ({ isOpen, onClose }) => {
	const depositToList = useSelector((state: ApplicationState) => state.deposit.depositToList)

	const depositToTruewallets = depositToList.filter((bank) => bank.bank === 'truewallet')
	if (depositToTruewallets.length <= 0) {
		// return <Redirect to="/deposit" />
		onClose()
	}

	return (
		<Modal
			className="ant-modal-deposit"
			open={isOpen}
			title="โอนผ่านทรูวอลเล็ท"
			centered
			onCancel={onClose}
			footer={null}
		>
			<div className="notice-card warning">
				<div className="notcie-card-icon">
					<img src="/icons/new/notice.svg" alt="notice icon" />
				</div>
				<Text className="notice-card-text">
					ระบบจะเติมเงินอัตโนมัติเข้ายูสเซอร์ของท่าน <Text type="warning"><strong>ภายใน 30 วินาที</strong></Text>
				</Text>
			</div>
			<div className="notice-card danger">
				<div className="notcie-card-icon">
					<img src="/icons/new/danger.svg" alt="notice icon" />
				</div>
				<Text className="notice-card-text">
					สามารถโอนเงินเข้าทรูมันนี่วอลเล็ทโดยใช้ <Text type="danger"><strong>ชื่อและเบอร์โทรศัพท์</strong></Text> ที่สมัครโอนเข้ามาเท่านั้น
				</Text>
			</div>
			{depositToTruewallets.map((bank) => (
				<WalletDepositItem bank={bank} key={bank.accountNumber} />
			))}
		</Modal>
	)
}

export default WalletDeposit
