import React, { FC, useEffect, useState } from 'react'
import { Button } from 'antd'
import { Member } from '../../@types/member'
import { getBankName } from '../../helpers/bank'

interface Props {
    qrCodeUrl: string
    bankLogoUrl: string
    info: Member
    amount: string
}

const BankQrCodeCapture: FC<Props> = ({ qrCodeUrl, bankLogoUrl, info, amount }) => {
    const [loading, setLoading] = useState(false);
    const [dataUrl, setDataUrl] = useState<string | null>(null);

    useEffect(() => {
        const canvas = document.createElement('canvas');
        canvas.width = 400;
        canvas.height = 494;

        const ctx = canvas.getContext('2d');

        if (ctx) {
            const qrCode = new Image();
            const bankLogo = new Image();
            qrCode.crossOrigin = "anonymous"

            // image
            qrCode.onload = () => {
                ctx.drawImage(qrCode, (canvas.width - 150) / 2, 64, 150, 150);
                ctx.drawImage(bankLogo, 38, 292, 84, 84);

                bankLogo.onload = () => {
                    ctx.drawImage(bankLogo, 38, 292, 84, 84);

                    const qrCodeDataURL = canvas.toDataURL();
                    setDataUrl(qrCodeDataURL);
                    setLoading(false);
                };

                bankLogo.src = bankLogoUrl;
            };

            qrCode.src = qrCodeUrl;

            // body
            ctx.fillStyle = '#202020';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // title
            ctx.font = '24px DB HelvethaicaX';
            ctx.fillStyle = 'white';
            ctx.textBaseline = 'middle';
            ctx.textAlign = 'center';
            ctx.fillText('เติมเงินผ่าน QR Code', canvas.width / 2, 40);

            // amount
            ctx.font = '32px DB HelvethaicaX';
            ctx.fillStyle = 'white';
            ctx.textBaseline = 'middle';
            ctx.textAlign = 'center';
            ctx.fillText(`ยอดเงิน ${amount} บาท`, canvas.width / 2, 248);

            // rect bank card
            ctx.fillStyle = '#303030';
            ctx.beginPath();
            ctx.roundRect(24, 280, 352, 108, [20]);
            ctx.fill();

            // bank card -> bank name
            ctx.font = '22px DB HelvethaicaX';
            ctx.fillStyle = '#cccccc';
            ctx.textBaseline = 'middle'
            ctx.textAlign = 'left';
            ctx.fillText(getBankName(info?.bankCode), 136, 306);

            // bank card -> account number
            ctx.font = '36px DB HelvethaicaX';
            ctx.textBaseline = 'middle'
            ctx.textAlign = 'left';
            ctx.fillStyle = 'white';
            ctx.fillText(info?.bankNumber, 136, 336);

            // bank card -> account name
            ctx.font = '22px DB HelvethaicaX';
            ctx.fillStyle = '#cccccc';
            ctx.textBaseline = 'middle'
            ctx.textAlign = 'left';
            ctx.fillText(`${info?.firstName} ${info?.lastName}`, 136, 364);

            // rect remark
            ctx.fillStyle = '#271a17';
            ctx.strokeStyle = '#b63124';
            ctx.beginPath();
            ctx.roundRect(24, 404, 352, 64, [20]);
            ctx.fill();
            ctx.stroke();

            ctx.font = '24px DB HelvethaicaX';
            ctx.fillStyle = '#ff4545';
            ctx.textBaseline = 'middle'
            ctx.textAlign = 'left';
            ctx.fillText(`ใช้ ชื่อ/เลขบัญชี ที่สมัคร สแกน QR Code เท่านั้น`, 40, 436);
        }
    }, [amount, bankLogoUrl, info?.bankCode, info?.bankNumber, info?.firstName, info?.lastName, qrCodeUrl]);

    const downloadImage = () => {
        if (dataUrl) {
            setLoading(true);

            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = `qrcode_${Date.now()}.png`;
            link.click();

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    return (
        <div>
            {dataUrl && <img src={dataUrl} alt="qr code" style={{ display: 'none' }} />}
            <Button className="ant-btn-dark" onClick={downloadImage} loading={loading}>
                {loading ? 'กำลังดาวน์โหลด...' : 'ดาวน์โหลดรูป'}
            </Button>
        </div>
    );
};

export default BankQrCodeCapture