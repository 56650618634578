import React, { FC } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Prefix } from '../../@types/prefix'
import { makePath } from '../../helpers/path'
import { Member } from '../../@types/member'
import { Avatar } from 'antd';
import { setUser } from '../../store/user/user.actions'
import AuthService from '../../services/auth'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'

interface SidebarProps {
	prefix?: Prefix
	member?: Member
	showMenuSupport?: boolean
	isSound: boolean
	setIsToggleSound: (e: any) => void
}

const Sidebar: FC<SidebarProps> = ({ prefix, showMenuSupport, isSound, setIsToggleSound }) => {

	const dispatch = useDispatch()

	const refetchCredit = async () => {
		try {
			const res = await AuthService.getMemberInfo(true)
			dispatch(setUser(res))
		} catch ({ message }: any) {
			toast.error(message as string)
		}
	}

	return (
		<div className="sidebar">
			<div className="sidebar-inner">
				<Link to={makePath('/wallet')}>
					{prefix?.logo && prefix?.logo !== "" ? (
						<Avatar
							className="avatar-logo"
							src={prefix?.logo}
							shape="square"
							size={80}
							style={{ margin: '16px auto', display: 'block' }} />
					) : (
						<Avatar
							className="avatar-logo"
							shape="square"
							size={80}
							style={{ margin: '16px auto', display: 'block' }}>
							{prefix?.prefix}
						</Avatar>
					)}
				</Link>

				<ul className="menu-sidebar">
					<li>
						<NavLink to={makePath('/wallet')} activeClassName="active-menu" onClick={() => { setIsToggleSound(!isSound); refetchCredit() }}>
							<img src={`/icons/new/wallet.svg`} alt="icon" />
							<span>กระเป๋า</span>
						</NavLink>
					</li>
					<li>
						<NavLink to={makePath('/deposit')} activeClassName="active-menu" onClick={() => { setIsToggleSound(!isSound) }}>
							<img src={`/icons/new/deposit.svg`} alt="icon" />
							<span>เติมเงิน</span>
						</NavLink>
					</li>
					<li>
						<NavLink to={makePath('/withdraw')} activeClassName="active-menu" onClick={() => { setIsToggleSound(!isSound) }}>
							<img src={`/icons/new/withdraw.svg`} alt="icon" />
							<span>ถอนเงิน</span>
						</NavLink>
					</li>
					<li>
						<NavLink to={makePath('/transactions')} activeClassName="active-menu" onClick={() => { setIsToggleSound(!isSound) }}>
							<img src={`/icons/new/history.svg`} alt="icon" />
							<span>ประวัติ</span>
						</NavLink>
					</li>
					<li>
						<NavLink to={makePath('/promotion')} activeClassName="active-menu" onClick={() => { setIsToggleSound(!isSound) }}>
							<img src={`/icons/new/promotion.svg`} alt="icon" />
							<span>โปรโมชั่น</span>
						</NavLink>
					</li>
					{prefix?.wheel.enable && (
						<li>
							<NavLink to={makePath('/lucky-wheel')} activeClassName="active-menu" onClick={() => { setIsToggleSound(!isSound) }}>
								<img src={`/icons/new/wheel.svg`} alt="icon" />
								<span>กงล้อ</span>
							</NavLink>
						</li>
					)}
					{prefix?.commission ? (
						<li>
							<NavLink to={makePath('/commission')} activeClassName="active-menu" onClick={() => { setIsToggleSound(!isSound) }}>
								<svg height="512" viewBox="0 0 36 36" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m33.38 26.09a2.14 2.14 0 0 0 -1.51-.63h-1.07v-1.06a5.33 5.33 0 0 0 -5.33-5.33h-6.4v-4.27h3.2a3.2 3.2 0 0 0 3.2-3.2v-6.4a3.2 3.2 0 0 0 -3.2-3.2h-8.54a3.2 3.2 0 0 0 -3.2 3.2v6.4a3.2 3.2 0 0 0 3.2 3.2h3.2v4.27h-6.4a5.33 5.33 0 0 0 -5.33 5.33v1.07h-1.07a2.13 2.13 0 0 0 -2.13 2.13v4.27a2.13 2.13 0 0 0 2.13 2.13h4.27a2.13 2.13 0 0 0 2.13-2.13v-4.27a2.13 2.13 0 0 0 -2.13-2.13h-1.07v-1.07a3.2 3.2 0 0 1 3.2-3.2h6.4v4.27h-1.06a2.13 2.13 0 0 0 -2.13 2.13v4.27a2.13 2.13 0 0 0 2.13 2.13h4.27a2.13 2.13 0 0 0 2.13-2.13v-4.27a2.13 2.13 0 0 0 -2.13-2.13h-1.07v-4.27h6.4a3.2 3.2 0 0 1 3.2 3.2v1.07h-1.07a2.13 2.13 0 0 0 -2.13 2.13v4.27a2.13 2.13 0 0 0 2.13 2.13h4.27a2.13 2.13 0 0 0 2.13-2.13v-4.27a2.14 2.14 0 0 0 -.62-1.51zm-20.71-14.49v-6.4a1.07 1.07 0 0 1 1.07-1.07h8.53a1.07 1.07 0 0 1 1.06 1.07v6.4a1.07 1.07 0 0 1 -1.07 1.07h-8.53a1.07 1.07 0 0 1 -1.06-1.07zm-4.27 16v4.27h-4.27v-4.27zm11.73 0v4.27h-4.26v-4.27h4.27zm11.73 0v4.27h-4.26v-4.27h4.27z" /><path d="m14.8 7.33h6.4v2.13h-6.4z" /></svg>
								<span>คอมมิชชั่น</span>
							</NavLink>
						</li>
					) : (
						<li>
							<NavLink to={makePath('/affiliate')} activeClassName="active-menu" onClick={() => { setIsToggleSound(!isSound) }}>
								<img src={`/icons/new/affiliate.svg`} alt="icon" />
								<span>ชวนเพื่อน</span>
							</NavLink>
						</li>
					)}
					{(prefix?.functions.rank && prefix?.ranks) && (
						<li>
							<NavLink to={makePath('/ranking')} activeClassName="active-menu" onClick={() => { setIsToggleSound(!isSound) }}>
								<img src={`/icons/new/ranking.svg`} alt="icon" />
								<span>จัดอันดับ</span>
							</NavLink>
						</li>
					)}
					<li>
						<NavLink to={makePath('/profile')} activeClassName="active-menu" onClick={() => { setIsToggleSound(!isSound) }}>
							<img src={`/icons/new/account.svg`} alt="icon" />
							<span>โปรไฟล์</span>
						</NavLink>
					</li>

					{/* check link support */}
					{!showMenuSupport && (
						<li>
							<NavLink to={makePath('/support')} activeClassName="active-menu" onClick={() => { setIsToggleSound(!isSound) }}>
								<img src={`/icons/new/help.svg`} alt="icon" />
								<span>แจ้งปัญหา</span>
							</NavLink>
						</li>
					)}
					{prefix?.lineLink && showMenuSupport && (
						<li>
							<a href={prefix.lineLink} target="_blank" rel="noreferrer" onClick={() => { setIsToggleSound(!isSound) }}>
								<img src={`/icons/new/help.svg`} alt="icon" />
								<span>แจ้งปัญหา</span>
							</a>
						</li>
					)}
					<li>
						<NavLink to={makePath('/logout')}>
							<img src={`/icons/new/logout.svg`} alt="icon" />
							<span>ออกจากระบบ</span>
						</NavLink>
					</li>
				</ul>
			</div>
		</div>
	)
}
export default Sidebar
