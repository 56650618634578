import { FC, useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { makePath } from '../../helpers/path'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { format } from '../../helpers/number'
import AuthService from '../../services/auth'
import { setUser } from '../../store/user/user.actions'
import { Button, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { LoadingOutlined, RedoOutlined } from '@ant-design/icons'
import { ApplicationState } from '../../store'
import { topbarText } from '../../constant'
import ModalComponents from '../Modal/ModalComponents'

interface BalanceWidgetProps {
	isTurnover: boolean
	credit: number
	totalTurn: number
	currentTurnover: number
	isCashback?: boolean
}

const BalanceWidget: FC<BalanceWidgetProps> = ({
	credit,
}) => {
	const history = useHistory()
	const userState = useSelector((state: ApplicationState) => state.user)
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const dispatch = useDispatch()
	const [fetching, setFetching] = useState(false)
	const [isTextOverflow, setIsTextOverflow] = useState(false)
	const myPromotionRef = useRef<HTMLDivElement>(null);

	const [titleModal, setTitleModal] = useState<string>("")
	const [keyModal, setKeyModal] = useState<string>("")
	const [openModal, setOpenModal] = useState<boolean>(false)
	const handleModal = () => setOpenModal(!openModal)

	const characterImage = prefixState?.info?.characterImage !== "" ? prefixState?.info?.characterImage : `/elements/bird.png`;

	const promotions = prefixState.info?.promotions || [];
	const myPromotionName = promotions.find(promotion => promotion.key === userState?.myPromotionKey);

	const refetchCredit = async () => {
		if (fetching) {
			return
		}
		setFetching(true)
		try {
			const res = await AuthService.getMemberInfo(true)
			dispatch(setUser(res))
		} catch ({ message }: any) {
			toast.error(message as string)
		} finally {
			setFetching(false)
		}
	}

	useEffect(() => {
		const checkWidthAndLog = () => {
			if (myPromotionRef.current) {
				const elementWidth = myPromotionRef.current.clientWidth;
				const threshold = window.innerWidth < 768 ? 190 : 250;

				if (elementWidth >= threshold) {
					setIsTextOverflow(true)
				} else {
					setIsTextOverflow(false)
				}
			}
		};

		// เรียกฟังก์ชันตรวจสอบเมื่อ component ถูก render และทุกรอบการ render
		checkWidthAndLog();

		// เพิ่ม event listener เพื่อตรวจสอบเมื่อขนาดหน้าต่างเปลี่ยนแปลง
		window.addEventListener('resize', checkWidthAndLog);

		// คืนค่าฟังก์ชันเพื่อทำความสะอาดเมื่อ component ถูกถอดจาก DOM
		return () => {
			window.removeEventListener('resize', checkWidthAndLog);
		};
	}, []);

	return (
		<>
			<div className="wallet-container">
				<img className="wallet-char-element" src={characterImage} alt="charecter" />
				<img className="wallet-gold-element" src={'/elements/gold.png'} alt="gold" />
				<span>
					<span style={{ opacity: 0.75 }}>ยอดเงินทั้งหมด</span>
					{fetching ? (
						<LoadingOutlined style={{ fontSize: 18, marginLeft: 6 }} rev={undefined} />
					) : (
						<Tooltip
							placement="right"
							title={'อัพเดทยอดเงิน'}
							color='#121416'
						>
							<RedoOutlined
								className="redo-button"
								onClick={refetchCredit}
								style={{ fontSize: 18, marginLeft: 6 }} rev={undefined} />
						</Tooltip>
					)}
				</span>
				<div className="wallet-amount">฿ {format(credit)}</div>
				<Button className="ant-btn-light" style={{ width: 120 }}
					onClick={() => {
						handleModal()
						setTitleModal(topbarText['/deposit'])
						setKeyModal("deposit")
					}}>
					<PlusOutlined style={{ fontSize: 14 }} rev={undefined} />
					<span>เติมเงิน</span>
				</Button>
				<div ref={myPromotionRef} className={`my-promotion ${userState?.myPromotionKey !== '' ? 'get-bonus' : ''}`}>
					<div className={`my-promotion-body ${isTextOverflow ? 'overflow' : ''}`}>
						<img src="/icons/new/promotion.svg" alt="" />
						<span>{userState?.myPromotionKey === '' ? 'ยังไม่ได้รับโบนัส' : `รับโบนัส ${myPromotionName?.name}`}</span>
					</div>
				</div>
			</div>

			<ModalComponents
				title={titleModal}
				keyModal={keyModal}
				isOpen={openModal}
				onClose={() => setOpenModal(false)} />
		</>
	)
}

export default BalanceWidget
