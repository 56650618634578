export const findCategoryGame = (type: string) => {
    switch (type) {
        case "all":
            return "ทั้งหมด"

        case "CARD":
            return "เกมไพ่"

        case "ARCADE":
            return "อาร์เคด"

        case "TABLE":
            return "เกมโต๊ะ"

        case "BACCARAT":
            return "บาคาร่า"

        case "DRAGON_TIGER":
            return "เสือมังกร"

        case "ROULETTE":
            return "รูเล็ต"

        case "4POINT":
            return "4Point"

        case "COWCOW":
            return "CowCow"

        case "SUPER6":
            return "Super6"

        case "CASINO":
            return "คาสิโน"

        case "SICBO":
            return "ไฮโล"

        case "DICEDUEL":
            return "ลูกเต๋า"

        case "LUCKY":
            return "โชคดี"

        case "POKER":
            return "โป๊กเกอร์"

        case "SIC_BO":
            return "ไฮโล"

        case "ESPORT":
            return "อีสปอร์ต"

        case "TRADE":
            return "ซื้อขาย"

        case "JACKPOTS":
            return "แจ็คพอต"

        case "FISH":
            return "ยิงปลา"

        case "ATOM":
            return "อะตอม"

        case "KENO":
            return "คีโน่"

        case "NUMBER":
            return "ตัวเลข"

        case "RNGWAR":
            return "สุ่มตัวเลข"

        case "BUY_FREE_SPINS":
            return "ซื้อฟรีสปิน"

        case "MEGAWAYS":
            return "เมกาเวย์"

        case "SLOT":
            return "สล็อต"
            
        default:
            return type
    }
}

export const findCategoryLotto = (type: string) => {
    switch (type) {
        case "all":
            return "ทั้งหมด"

        case "LOTTO":
            return "หวย"

        case "YEEKEE":
            return "หวยยี่กี"

        case "THAI":
            return "หวยไทย"

        case "STOCK":
            return "หวยหุ้น"

        case "MALAY":
            return "หวยมาเลย์"

        case "PINGPONG":
            return "หวยปิงปอง"

        case "VIETNAM":
            return "หวยเวียดนาม"

        case "LAOS":
            return "หวยลาว"
            
        default:
            return type
    }
}