import React, { FC, useEffect, useState } from 'react'
import { Checkbox, Modal, Row } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

interface PopupProps {
    isOpen: boolean
    data: any
    onClose: () => void
    setIsDontShow: (e: any) => void
}

const Popup: FC<PopupProps> = ({ isOpen, onClose, data, setIsDontShow }) => {
    const [dataPopup, setDataPopup] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [loadingImage, setLoadingImage] = useState<boolean>(true)

    const handleImageLoad = () => {
        setLoadingImage(false);
    };

    useEffect(() => {
        if (isOpen && Object.keys(data).length > 0) {
            let popupData = Object.values(data).map((element: any, idx: any) => {
                const item: any = element
                const keyPopup = Object.keys(data)[idx];
                item.key = keyPopup
                return item
            });
            setLoading(false)
            setDataPopup(popupData)
        }
    }, [isOpen, data])

    return (
        <Modal
            className="ant-popup"
            open={isOpen}
            title=""
            centered
            onCancel={onClose}
            footer={null}
        >
            {loading ?
                <Row justify="center" align="top" >
                    <LoadingOutlined className="primary-color" style={{ fontSize: '64px' }} rev={undefined} />
                </Row>
                :
                <>
                    <Carousel
                        autoPlay={true}
                        showStatus={false}
                        showThumbs={false}
                        showArrows={false}
                        interval={4000}
                        infiniteLoop={true}
                        dynamicHeight={true}
                    >
                        {dataPopup.map((item: any, idx: any) => {
                            return (
                                <div className="test-slider" key={item.key}>
                                    <img src={item.image} onLoad={handleImageLoad} alt="banner" style={{ display: loadingImage ? 'none' : 'block' }} />
                                </div>
                            )
                        })}
                    </Carousel>
                    <div className="ant-pop-checkbox">
                        <Checkbox onChange={() => setIsDontShow(true)}>ไม่ต้องแสดงอีก</Checkbox>
                    </div>
                </>
            }
        </Modal>
    )
}

export default Popup
