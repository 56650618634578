import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { makePath } from '../../helpers/path'

interface WalletMobileMenuProps {
	lineLink: string
	wheel: boolean
	commission: boolean
	showMenuSupport?: boolean
	ranking?: boolean
	alert: () => void
	modalPromotions: () => void
	modalSupport: () => void
}
const WalletMobileMenu: FC<WalletMobileMenuProps> = ({ lineLink, wheel, commission, showMenuSupport, ranking, alert, modalPromotions, modalSupport }) => (
	<div className="menu-mobile-wrapper" style={{ marginTop: 24 }}>
		<div className="menu-mobile-col">
			<Link
				to={makePath('/login')}
				className="menu-mobile">
				<div className="menu-mobile-icon">
					<img src={`/icons/new/deposit.svg`} alt="icon" />
				</div>
				เติมเงิน
			</Link>
		</div>
		<div className="menu-mobile-col">
			<Link
				to={makePath('/login')}
				className="menu-mobile">
				<div className="menu-mobile-icon">
					<img src={`/icons/new/withdraw.svg`} alt="icon" />
				</div>
				ถอนเงิน
			</Link>
		</div>
		<div className="menu-mobile-col">
			<Link to={"#"} onClick={modalPromotions} className="menu-mobile">
				<div className="menu-mobile-icon">
					<img src={`/icons/new/promotion.svg`} alt="icon" />
				</div>
				โปรโมชั่น
			</Link>
		</div>
		<div className="menu-mobile-col">
			<Link
				to={makePath('/login')}
				className="menu-mobile">
				<div className="menu-mobile-icon">
					<img src={`/icons/new/history.svg`} alt="icon" />
				</div>
				ประวัติ
			</Link>
		</div>
		{commission ? (
			<div className="menu-mobile-col">
				<Link
					to={makePath('/login')}
					className="menu-mobile">
					<div className="menu-mobile-icon">
						<img src={`/icons/new/affiliate.svg`} alt="icon" />
					</div>
					คอมมิชชั่น
				</Link>
			</div>
		) : (
			<div className="menu-mobile-col">
				<Link
					to={makePath('/login')}
					className="menu-mobile">
					<div className="menu-mobile-icon">
						<img src={`/icons/new/affiliate.svg`} alt="icon" />
					</div>
					ชวนเพื่อน
				</Link>
			</div>
		)}

		{wheel && (
			<div className="menu-mobile-col">
				<Link
					to={makePath('/login')}
					className="menu-mobile">
					<div className="menu-mobile-icon">
						<img src={`/icons/new/wheel.svg`} alt="wheel icon" />
					</div>
					กงล้อ
				</Link>
			</div>
		)}

		{!showMenuSupport && (
			<div className="menu-mobile-col">
				<Link to={"#"} onClick={modalSupport} className="menu-mobile">
					<div className="menu-mobile-icon">
						<img src={`/icons/new/help.svg`} alt="chat icon" />
					</div>
					แจ้งปัญหา
				</Link>
			</div>
		)}

		{lineLink && showMenuSupport && (
			<div className="menu-mobile-col">
				<a href={lineLink} target="_blank" rel="noreferrer" className="menu-mobile">
					<div className="menu-mobile-icon">
						<img src={`/icons/new/help.svg`} alt="icon" />
					</div>
					แจ้งปัญหา
				</a>
			</div>
		)}

		{ranking && (
			<div className="menu-mobile-col">
				<Link
					to={makePath('/login')}
					className="menu-mobile">
					<div className="menu-mobile-icon">
						<img src={`/icons/new/ranking.svg`} alt="affiliate icon" />
					</div>
					จัดอันดับ
				</Link>
			</div>
		)}

	</div>
)

export default WalletMobileMenu
