import { Card, Col, Flex, Modal, Row, Space, Typography, } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { browserName, isBrowser, isMobile } from 'react-device-detect';

const { Link, Text } = Typography

interface ModalAddHomeProps {
    isOpen: boolean
    onClose: () => void
}
const ModalAddHome: FC<ModalAddHomeProps> = ({ isOpen, onClose }) => {
    const prefixState = useSelector((state: ApplicationState) => state.prefix)

    const prefix = prefixState.info
    console.log(isBrowser, isMobile, browserName);


    return (
        <Modal
            className="ant-modal-deposit"
            open={isOpen}
            title="วิธีการติดตั้ง"
            centered
            onCancel={() => onClose()}
            footer={false}
        >
            <Row gutter={[{ xs: 24, sm: 24 }, { xs: 24, sm: 24 }]}>
                <Text strong>คุณสามารถเพิ่มเว็บไซต์นี้ไปยัง หน้าจอหลัก เพียงทำตามวิธีการที่ระบุไว้ด้านล่าง</Text>
                {isMobile ? (
                    <>
                        {browserName === 'Mobile Safari' ? (
                            <Col xs={24} sm={24}>
                                <Space direction="vertical" style={{ width: '100%' }}>
                                    <Card>
                                        <Flex gap={20} align="center" justify="flex-start" >
                                            <LazyLoadImage
                                                src="/icons/browser/safari/safari01.svg"
                                                alt="safari01"
                                                effect="blur"
                                                style={{ width: '100%' }}
                                            />
                                            <Text>
                                                1. คลิกไปยังสัญลักษณ์ เพื่อเปิดเมนูสำหรับติดตั้งเว็บไซต์
                                                <LazyLoadImage
                                                    src="/icons/browser/safari/safari02.jpg"
                                                    alt="safari02"
                                                    effect="blur"
                                                    style={{ width: '100%' }}
                                                />
                                            </Text>
                                        </Flex>
                                    </Card>
                                    <Card>
                                        <Flex align="center" justify="flex-start" >
                                            <LazyLoadImage
                                                src="/icons/browser/safari/safari03.svg"
                                                alt="safari01"
                                                effect="blur"
                                                style={{ width: '100%' }}
                                            />
                                            <Text>
                                                2. เลือกเมนู "เพิ่มไปยังหน้าจอโฮม" หรือที่มีสัญลักษณ์ จากนั้นเลือก "เพิ่ม" เพื่อติดตั้งไปยังหน้าจอหลัก
                                                <LazyLoadImage
                                                    src="/icons/browser/safari/safari04.jpg"
                                                    alt="safari02"
                                                    effect="blur"
                                                    style={{ width: '100%' }}
                                                />
                                            </Text>
                                        </Flex>
                                    </Card>
                                </Space>
                            </Col>
                        ) : (
                            <Col xs={24} sm={24}>
                                <Space direction="vertical" style={{ width: '100%' }}>
                                    <Text strong>Chrome Mobile</Text>
                                    <Text>1. กดที่ปุ่ม <strong>เพิ่มไปยังหน้าจอหลัก</strong> ด้านล่างของหน้าจอ</Text>
                                    <Text>2. กดที่ปุ่ม <strong>เพิ่ม</strong></Text>
                                    <Text>3. กดที่ <strong>เปิด</strong></Text>
                                </Space>
                            </Col>
                        )}
                    </>
                ) : (
                    <Col xs={24} sm={24}>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <Text strong>คุณสามารถเพิ่มเว็บไซต์นี้ไปยัง หน้าจอหลัก เพียงทำตามวิธีการที่ระบุไว้ด้านล่าง</Text>
                            <Card>
                                <Text>สำหรับ Mac OS หรือ Windows คุณสามารถลาก ลิงก์ หรือ URL ไปยังหน้าจอหลักของคุณ เพียงเท่านี้คุณก็จะได้ทางเข้าเว็บไซต์แบบรวดเร็ว</Text>
                            </Card>
                        </Space>
                    </Col>
                )}
            </Row>
        </Modal>
    )
}

export default ModalAddHome
