import React, { useEffect, useState } from 'react'
import { AMBKingConfig } from '../../entities/AMBKing'
import ProviderList from './AMBKing1/ProviderList'
import GameList from './AMBKing1/GameList'
import ShowGame from './AMBKing1/ShowGame'
import { Empty, Skeleton } from 'antd'
import { CategoryGameAMBKing1 } from '../../utils/categoryGame'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import { useLocation, useHistory } from 'react-router-dom';
import MainSlider from '../Wallet/MainSlider'
import Footer from '../Layout/Footer'
import SeoContent from '../Layout/SeoContent'
import LoadingLayout from '../Layout/LoadingLayout'
import { makePath } from '../../helpers/path'
import Popup from '../../components/Popup'

const AMBKing = () => {
    const location = useLocation();
    const history = useHistory();

    const [config, setConfig] = useState<AMBKingConfig | undefined>(undefined)
    const [provider, setProvider] = useState('')
    const [pathName, setPathName] = useState<string>('')
    const [gameType, setGameType] = useState<string>('slot')
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingProvider, setLoadingProvider] = useState<boolean>(false)
    const [loadingAMBKing, setLoadingAMBKing] = useState<boolean>(false)
    const [allProvider, setAllProvider] = useState<any>(undefined)
    const [openPopup, setOpenPopup] = useState(false)
    const [isDontShow, setIsDontShow] = useState<boolean>(false)

    const providerState = useSelector((state: ApplicationState) => state.provider)
    const providerHomeState = useSelector((state: ApplicationState) => state.gamesHome)
    const prefixState = useSelector((state: ApplicationState) => state.prefix)

    const closePopup = () => {
        setOpenPopup(false)

        if (isDontShow) {
            const currentTime = new Date();
            currentTime.setHours(currentTime.getHours() + 1);
            const expirationTime = currentTime.getTime();
            localStorage.setItem('popup', "close")
            localStorage.setItem('popupExpiration', expirationTime.toString());
        }

    }

    useEffect(() => {
        if (prefixState.info && location.pathname === '/launch-game') {
            if (prefixState.info?.popup && (Object.keys(prefixState.info?.popup).length) > 0 && localStorage.getItem('popup') !== "close") {
                setOpenPopup(true)
            }
        }

        const expirationTime = localStorage.getItem('popupExpiration');
        if (expirationTime && (new Date().getTime() > Number(expirationTime))) {
            localStorage.removeItem('popup');
            localStorage.removeItem('popupExpiration');
        }
    }, [prefixState]) // eslint-disable-line

    useEffect(() => {
        if (providerState?.info !== undefined && location.pathname === '/launch-game') {
            setConfig(providerState?.info)
            setAllProvider(providerState?.info?.games)
            if (Object.values(providerState?.info?.games).length === 1) {
                setGameType(Object.keys(providerState?.info?.games)[0]);
            }

            setLoadingAMBKing(true)
        }

        if (providerHomeState?.info !== undefined && location.pathname === '/home') {
            setConfig(providerHomeState?.info)
            setAllProvider(providerHomeState?.info?.games)
            if (Object.values(providerHomeState?.info?.games).length === 1) {
                setGameType(Object.keys(providerHomeState?.info?.games)[0]);
            }

            setLoadingAMBKing(true)
        }

        setPathName(location.pathname === '/launch-game' ? 'launch-game' : 'home')

    }, [providerState?.info, providerHomeState?.info, location]) // eslint-disable-line

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const gameTypeQuery = params.get('gameType');
        const providerQuery = params.get('provider');

        const getIsGamePromotion = localStorage.getItem('isGamePromotion')
        if (getIsGamePromotion && getIsGamePromotion === 'on') {
            document.getElementById(gameType)?.click()
        }

        if (gameTypeQuery) {
            setGameType(gameTypeQuery);
            params.set('gameType', gameTypeQuery);
            history.push({ search: params.toString() });
        } else {
            for (const gameType of Object.values(CategoryGameAMBKing1)) {
                if (Object(allProvider)[(gameType.key).toLowerCase()] !== undefined) {
                    setGameType(gameType.key);
                    params.set('gameType', gameType.key);
                    history.push({ search: params.toString() });
                    break;
                }
            }
        }

        if (providerQuery) {
            if (getIsGamePromotion && getIsGamePromotion === 'on') {
                setProvider('');
            } else {
                setProvider(providerQuery);
            }
        }

        if (gameTypeQuery === 'favorite' && location.pathname === '/home') {
            params.set('gameType', 'slot');
            history.push({ search: params.toString() });
        }
    }, [allProvider, CategoryGameAMBKing1, location.search]) //eslint-disable-line

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const gameTypeQuery = params.get('gameType');
        const providerQuery = params.get('provider');
        const filterQuery = params.get('filter');

        const getIsGamePromotion = localStorage.getItem('isGamePromotion')
        if (getIsGamePromotion && getIsGamePromotion === 'on') {
            document.getElementById(gameType)?.click()
        }

        if (gameTypeQuery) {
            setGameType(gameTypeQuery);
            params.set('gameType', gameTypeQuery);
            params.set('filter', filterQuery ? filterQuery : 'off');
            history.push({ search: params.toString() });
        } else {
            for (const gameType of Object.values(CategoryGameAMBKing1)) {
                if (Object(allProvider)[(gameType.key).toLowerCase()] !== undefined) {
                    setGameType(gameType.key);
                    params.set('gameType', gameType.key);
                    params.set('filter', filterQuery ? filterQuery : 'off');
                    history.push({ search: params.toString() });
                    break;
                }
            }
        }

        if (providerQuery) {
            if (getIsGamePromotion && getIsGamePromotion === 'on') {
                setProvider('');
            } else {
                setProvider(providerQuery);
            }
        }

        if (gameTypeQuery === 'favorite' && location.pathname === '/home') {
            params.set('gameType', 'slot');
            history.push({ search: params.toString() });
        }
    }, [allProvider, CategoryGameAMBKing1, location.search]) //eslint-disable-line


    useEffect(() => {
        if (!provider) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [provider])

    // set provider to url
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        provider?.length ? params.set('provider', provider) : params.delete('provider');
        history.push({ search: params.toString() });
    }, [history, location.search, provider]);

    // get query string
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const gameTypeQuery = params.get('gameType');
        const providerQuery = params.get('provider');

        if (gameTypeQuery) {
            setGameType(gameTypeQuery);
        }

        if (providerQuery) {
            setProvider(providerQuery);
        }
        setLoading(false)
    }, [location.search]);

    if (!config) {
        return (
            <div>
                <div className="ambbet-topbar-skeleton">
                    <div className="amb-button-skeleton">
                        {
                            Array.from({ length: 10 }, (_, index) => (
                                <Skeleton.Button key={index} active />
                            ))
                        }
                    </div>
                </div>

                <div className="ambbet-provider-skeleton">
                    {
                        Array.from({ length: 24 }, (_, index) => (
                            <Skeleton.Button key={index} active />
                        ))
                    }
                </div>
            </div>
        )
    }

    const handleGameType = (game: string) => {
        const params = new URLSearchParams(location.search);

        window.scrollTo({ top: 0, behavior: 'smooth' });
        setProvider('')
        setGameType(game)
        params.set('gameType', game);

        params.delete('provider');
        history.push({ search: params.toString() });
    }

    const TabGameType: any = loadingAMBKing && CategoryGameAMBKing1.filter((item) => {
        if (Object(allProvider)[(item.key).toLowerCase()] === undefined) {
            return false;
        }
        return true;
    }).map((x) => {
        return (
            <div id={x.key} className={`ant-tabs-tab ${x.key === gameType ? 'ant-tabs-tab-active' : ''}`} key={`menu-tab-${x.key}`} onClick={() => handleGameType(x.key)}>
                <div className="ant-tabs-tab-btn">
                    <div className="menu-tab">
                        <div className="icon">
                            <img src={x.image} alt="nav" />
                        </div>
                        <span>
                            <strong>{x.title}</strong>
                        </span>
                    </div>
                </div>
            </div>
        )
    })



    return (
        <div className="ambking-container">
            <MainSlider prefixState={prefixState} />

            <div className="ant-tabs ant-tabs-top ambking-new-tab">
                <div className="ant-tabs-nav">
                    <div className="ant-tabs-nav-list">
                        <div className={`ant-tabs-tab ${gameType === 'favorite' ? 'ant-tabs-tab-active' : ''}`} key={`menu-tab-${`favorite`}`} onClick={() => location.pathname === '/launch-game' ? handleGameType('favorite') : history.push(makePath('/login'))}>
                            <div className="ant-tabs-tab-btn">
                                <div className="menu-tab">
                                    <div className="icon">
                                        <img src="/icons/3d/favorite.svg" alt="nav" />
                                    </div>
                                    <span>
                                        <strong>เกมโปรด</strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {TabGameType}
                    </div>
                </div>
                <div className="ambking-tab">
                    {loading ? (
                        <LoadingLayout prefix={prefixState} />
                    ) : (
                        !provider ? (
                            ((gameType === 'sport') || (gameType === 'lotto') || (gameType === 'favorite' && location.pathname === '/launch-game') ? (
                                <div className={`amb-games-container ${gameType === 'sport' ? 'amb-games-sport' : ''}`} key={`games-${gameType}`}>
                                    <ShowGame
                                        games={allProvider[(gameType).toLowerCase()]}
                                        provider={provider as string}
                                        setProvider={setProvider}
                                        gameType={gameType}
                                        setLoading={setLoading}
                                        pathName={pathName}
                                    />
                                </div>
                            ) :
                                (Object(allProvider)[(gameType).toLowerCase()] !== undefined && Object(allProvider)[(gameType).toLowerCase()].length > 0 ? (
                                    <ProviderList
                                        key={`ProviderList_${gameType}_${gameType}`}
                                        config={allProvider[(gameType).toLowerCase()]}
                                        type={gameType}
                                        setProvider={setProvider}
                                        setGameType={setGameType}
                                    />
                                ) :
                                    <div style={{ display: 'flex', width: '100%', marginTop: '12px' }}>
                                        <Empty
                                            description='ไม่พบเกมส์'
                                            image="/icons/new/empty-box.svg"
                                            imageStyle={{ height: 88 }}
                                            style={{ flex: 'auto', marginTop: 24 }}
                                        />
                                    </div>
                                )
                            )
                        ) : (
                            <div key={`games-${gameType}`}>
                                <GameList
                                    games={allProvider[gameType]}
                                    provider={provider as string}
                                    gameType={gameType}
                                    loadingProvider={loadingProvider}
                                    setProvider={setProvider}
                                    setLoadingProvider={setLoadingProvider}
                                    setLoading={setLoading}
                                    pathName={pathName}
                                />
                            </div>
                        )
                    )}
                </div>
            </div>

            {location.pathname === '/home' && <SeoContent />}

            <Footer prefixState={prefixState} />

            {/* Popup */}
            {prefixState.info?.popup && (Object.keys(prefixState.info?.popup).length) > 0 && (
                <Popup data={prefixState.info?.popup} isOpen={openPopup} onClose={closePopup} setIsDontShow={setIsDontShow} />
            )}

        </div>
    )
}

export default AMBKing
