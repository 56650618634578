import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import BalanceWidget from '../components/Wallet/BalanceWidget'
import TurnWidget from '../components/Wallet/TurnWidget'
import CashbackWidget from '../components/Wallet/CashbackWidget'
import WalletMobileMenu from '../components/Wallet/WalletMobileMenu'
import WalletPromotionContainer from '../components/Wallet/WalletPromotionContainer'
import Popup from '../components/Popup'
import MainSlider from '../components/Wallet/MainSlider'
import { ApplicationState } from '../store'
import Footer from '../components/Layout/Footer'
import { useHistory, useLocation } from 'react-router'
// import { FloatButton } from 'antd'
// import { UpOutlined } from '@ant-design/icons';
// import ButtonFooter from '../components/Layout/ButtonFooter'

const Wallet = () => {
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const userState = useSelector((state: ApplicationState) => state.user)
	const [openPopup, setOpenPopup] = useState(false)
	const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)
	const [isDontShow, setIsDontShow] = useState<boolean>(false)
	const [modalQuery, setModalQuery] = useState<string>("")

	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		if (prefixState.info) {
			const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
				if (value === "") {
					return true;
				}
				return false;
			}) : true;
			setShowMenuSupport(isNullishSocialMedia)

			if (prefixState.info?.popup && (Object.keys(prefixState.info?.popup).length) > 0 && localStorage.getItem('popup') !== "close") {
				setOpenPopup(true)
			}
		}

		const expirationTime = localStorage.getItem('popupExpiration');
		if (expirationTime && (new Date().getTime() > Number(expirationTime))) {
			localStorage.removeItem('popup');
			localStorage.removeItem('popupExpiration');
		}
	}, [prefixState])

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const modalQuery = params.get('modal');
		if (modalQuery) {
			setModalQuery(modalQuery);
			params.delete('modal');
			history.push({ search: params.toString() });
		}
	}, [location.search, history])

	const closePopup = () => {
		setOpenPopup(false)

		if (isDontShow) {
			const currentTime = new Date();
			currentTime.setHours(currentTime.getHours() + 1);
			const expirationTime = currentTime.getTime();
			localStorage.setItem('popup', "close")
			localStorage.setItem('popupExpiration', expirationTime.toString());
		}
	}

	return (
		<>
			<MainSlider prefixState={prefixState} />
			<div className="wallet-row">
				<div className="wallet-col">
					<BalanceWidget
						isTurnover={prefixState.info?.isTurnover || false}
						credit={userState.credit}
						totalTurn={userState.info?.totalTurn || 0}
						currentTurnover={userState.currentTurnover}
						isCashback={userState.cashback.enable}
					/>
				</div>
				<div className="wallet-col">
					<div className="balance-other">
						<TurnWidget
							isTurnover={prefixState.info?.isTurnover || false}
							credit={userState.credit}
							totalTurn={userState.info?.totalTurn || 0}
							currentTurnover={userState.currentTurnover}
						/>
						{userState.cashback.enable && <CashbackWidget cashback={userState.cashback.total} />}
					</div>
				</div>
			</div>

			{/* Mobile menu */}
			<WalletMobileMenu
				lineLink={prefixState.info?.lineLink || ''}
				wheel={prefixState.info?.wheel.enable || false}
				commission={prefixState.info?.commission || false}
				showMenuSupport={showMenuSupport}
				ranking={prefixState.info?.functions.rank || false}
				showModal={modalQuery}
			/>

			{/* Promotion */}
			{(prefixState.info?.promotions.length || 0) > 0 && (
				<WalletPromotionContainer promotions={prefixState.info?.promotions} />
			)}

			{/* Popup */}
			{prefixState.info?.popup && (Object.keys(prefixState.info?.popup).length) > 0 && (
				<Popup data={prefixState.info?.popup} isOpen={openPopup} onClose={closePopup} setIsDontShow={setIsDontShow} />
			)}

			<Footer prefixState={prefixState} />
		</>
	)
}

export default Wallet
