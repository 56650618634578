import moment from 'moment'
import React, { FC, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { ApplicationState } from '../../store'
import { Transaction } from '../../@types/transaction'
import { getBankName } from '../../helpers/bank'
import { format } from '../../helpers/number'
import { makePath } from '../../helpers/path'
import StatusBadge from './StatusBadge'
import { Button, Empty, Tag, Typography } from 'antd'

const { Text } = Typography

interface WithdrawListProps {
	data: Transaction[]
}

const WithdrawList: FC<WithdrawListProps> = ({ data }) => {
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const prefix = prefixState.info

	const [showMenuSupportLine, setShowMenuSupportLine] = useState<boolean>(false)
	const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)
	useEffect(() => {
		if (prefixState.info) {
			const isNullishSocialMediaLine = prefixState.info?.socialMedia ?
				(Object.keys(prefixState.info?.socialMedia).find((item: any) => item === "line") && prefixState.info?.socialMedia.line !== "" ? true : false)
				: false;
			setShowMenuSupportLine(isNullishSocialMediaLine)

			const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
				if (value === "") {
					return true;
				}
				return false;
			}) : true;
			setShowMenuSupport(isNullishSocialMedia)
		}
	}, [prefixState])

	if (data.length <= 0) {
		return (
			<div style={{ padding: '24px 0' }}>
				<Empty
					description='คุณไม่มีรายการถอนเงิน'
					image="/icons/new/empty-box.svg"
					imageStyle={{ height: 88 }}
				/>
			</div>
		)
	}

	return (
		<>
			{data.map((tx) => (
				<div className="transaction-card" key={tx.id}>
					<div className="transaction-header">
						<div className="transaction-info">
							<div className="transaction-title">ถอนเงิน</div>
							<Text>ไปยัง {getBankName(tx.transferTo)}</Text>
						</div>
						<div className="transaction-title">฿ {format(tx.amount)}</div>
					</div>

					<div className="transaction-body">
						<div className="tag-default">{moment(tx.createdAt).format('DD/MM/YYYY HH:mm')}</div>
						<StatusBadge status={tx.status} />
					</div>

					{tx.status === 'reject' && tx.remark
						&& (
							<>
								{showMenuSupportLine && (
									<div className="transaction-footer">
										<a href={prefixState.info?.socialMedia['line']} className="btn btn--primary" target="_blank" rel="noreferrer">
											<Button type="primary" style={{ display: 'flex', justifyContent: 'center' }}>
												<span className="material-symbols-rounded" style={{ fontSize: 22, marginRight: 8 }}>
													support_agent
												</span>
												<span>ติดต่อเจ้าหน้าที่</span>
											</Button>
										</a>
									</div>
								)}

								{!prefix?.lineLink && !showMenuSupport && !showMenuSupportLine && (
									<div className="transaction-footer">
										<NavLink to={makePath('/support')} className="btn btn--primary">
											<Button type="primary" style={{ display: 'flex', justifyContent: 'center' }}>
												<span className="material-symbols-rounded" style={{ fontSize: 22, marginRight: 8 }}>
													support_agent
												</span>
												<span>ติดต่อเจ้าหน้าที่</span>
											</Button>
										</NavLink>
									</div>
								)}

								{prefix?.lineLink && !showMenuSupportLine && (
									<div className="transaction-footer">
										<a href={prefix.lineLink} className="btn btn--primary" target="_blank" rel="noreferrer">
											<Button type="primary" style={{ display: 'flex', justifyContent: 'center' }}>
												<span className="material-symbols-rounded" style={{ fontSize: 22, marginRight: 8 }}>
													support_agent
												</span>
												<span>ติดต่อเจ้าหน้าที่</span>
											</Button>
										</a>
									</div>
								)}
							</>
						)
					}
				</div>
			))}
		</>
	)
}

export default WithdrawList
