import React, { FC, useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Member } from '../../@types/member'
import { makePath } from '../../helpers/path'
import { ApplicationState } from '../../store'
import { format } from '../../helpers/number'
import { Alert, Avatar, Button, Drawer, Space, Typography } from 'antd';
import { RightOutlined, PlusOutlined } from '@ant-design/icons';
import WalletMobileMenu from '../../components/Wallet/WalletMobileMenu'
import ChangePassword from '../../components/Profile/ChangePassword'
import { isMobile } from '../../helpers/utils'
import { topbarText } from '../../constant'
import ModalComponents from '../Modal/ModalComponents'
import ModalAddHome from '../Modal/ModalAddHome'

const { Text } = Typography;

interface Props {
    prefix: any
    member?: Member
    setIsToggleSound?: (e: any) => void
}

const TopbarWidget: FC<Props> = ({ prefix, member, setIsToggleSound }) => {
    const history = useHistory()
    const loc = useLocation()
    const pathname = loc.pathname
    const isMobileDevice = isMobile()


    const [titleModal, setTitleModal] = useState<string>("")
    const [keyModal, setKeyModal] = useState<string>("")
    const [openModal, setOpenModal] = useState<boolean>(false)
    const handleModal = () => setOpenModal(!openModal)

    const [changePassword, setChangePassword] = useState<boolean>(false)
    const handleChangePassword = () => setChangePassword(!changePassword)

    const [openModalAddHome, setOpenModalAddHome] = useState<boolean>(false)
    const handleAddHome = () => setOpenModalAddHome(!openModalAddHome)

    const launchGame = () => {
        if (!member?.game.meta) {
            toast.error('กรุณาฝากเงินครั้งแรกเพื่อเข้าเล่นเกม')
            return
        }

        history.push(makePath('/launch-game'))
    }
    const prefixState = useSelector((state: ApplicationState) => state.prefix)
    const userState = useSelector((state: ApplicationState) => state.user)
    const firstName = member?.firstName as string
    const lastName = member?.lastName as string
    const myRank: any = userState.info ? userState.info.rank : { name: "", point: 0 }

    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [isSound, setIsSound] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const onGetLocalSound = () => {
        const getLocalSound = localStorage.getItem('sound')
        if (getLocalSound === 'On') {
            setIsSound(true)
        }
        if (getLocalSound === null) {
            localStorage.setItem('sound', 'On')
            setIsSound(true)
        }
    };

    const handleOutsideClick = (e: Event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        onGetLocalSound()
    }, [isSound])

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (prefixState.info) {
            const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
                if (value === "") {
                    return true;
                }
                return false;
            }) : true;

            setShowMenuSupport(isNullishSocialMedia)
        }
    }, [prefixState])

    return (
        <>
            <div className="topbar-widget">
                {pathname !== '/launch-game' && (
                    <Button
                        className="ant-btn-secondary"
                        onClick={launchGame}
                    >
                        เข้าเล่นเกม <RightOutlined style={{ fontSize: 14 }} rev={undefined} />
                    </Button>
                )}
                {((loc.pathname === '/wallet' || loc.pathname === '/launch-game') || !isMobileDevice) && (
                    <Link to={"#"}
                        onClick={() => {
                            handleModal()
                            setTitleModal(topbarText['/deposit'])
                            setKeyModal("deposit")
                        }}
                        className="balance-widget">
                        <div className="balance-widget-body">
                            <img className="balance-widget-icon" src={'/icons/new/baht.svg'} alt="baht" />
                            <Text className="balance-widget-number">{format(userState.credit)}</Text>
                        </div>
                        <div className="balance-widget-button">
                            <PlusOutlined rev={undefined} style={{ color: '#fff', fontSize: 18 }} />
                        </div>
                    </Link>
                )}
                <div className="menu-widget" ref={dropdownRef}>
                    <div className="menu-widget-button" onClick={toggleDropdown}>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" x="0" y="0" viewBox="0 0 24 24">
                            <g><g fill="#000" fillRule="evenodd" clipRule="evenodd"><path d="M2 6a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1zM8 12a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1zM6 18a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1z" fill="#ffffff" opacity="1" data-original="#000000"></path></g></g></svg>
                    </div>
                </div>
            </div>
            <Drawer className="drawer-profile" placement="right" onClose={toggleDropdown} open={isDropdownOpen} >
                <div className="topbar-member box-content">
                    <div className="topbar-balance">
                        {(prefixState?.info?.functions.rank && prefixState?.info?.ranks) ? (
                            <Link to={"#"}
                                onClick={() => {
                                    handleModal()
                                    setTitleModal(topbarText['/ranking'])
                                    setKeyModal("ranking")
                                }}>
                                <div className="topbar-profile-rank">
                                    <img src={`/ranks/${myRank.name === '' ? 'bronze' : myRank.name}.svg`} alt={myRank.name === '' ? 'bronze' : myRank.name} />
                                </div>
                            </Link>
                        ) : (
                            <Link to={"#"}
                                onClick={() => {
                                    handleModal()
                                    setTitleModal(topbarText['/profile'])
                                    setKeyModal("profile")
                                }}>
                                <div className="topbar-profile-rank" style={{ paddingLeft: 4 }}>
                                    <img src="/avatar.png" alt="avatar" />
                                </div>
                            </Link>
                        )}
                        <Link to={"#"}
                            onClick={() => {
                                handleModal()
                                setTitleModal(topbarText['/profile'])
                                setKeyModal("profile")
                            }} className="topbar-balance-number" style={{ marginRight: 0 }}>
                            <Text>{firstName} {lastName}</Text>
                            <div>
                                <Text type="secondary">฿{format(userState.credit)}</Text>
                            </div>
                        </Link>
                    </div>
                </div>
                <WalletMobileMenu
                    lineLink={prefixState.info?.lineLink || ''}
                    wheel={prefixState.info?.wheel.enable || false}
                    commission={prefixState.info?.commission || false}
                    showMenuSupport={showMenuSupport}
                    ranking={prefixState.info?.functions.rank || false}
                />
                {/* <Button className="ant-btn-primary" style={{ marginTop: 'auto' }} block onClick={handleChangePassword}>เพิ่มไปยังหน้า Home</Button> */}
                <Alert
                    message="เพิ่มไปยังหน้า Home"
                    description="เข้าเว็บสะดวกง่ายและรวดเร็ว"
                    type="info"
                    showIcon
                    icon={prefix?.logo && prefix?.logo !== "" ? (
                        <Avatar
                            className="avatar-logo"
                            src={prefix?.logo}
                            shape="square"
                            size={50}
                            style={{ margin: '16px auto', display: 'block' }} />
                    ) : (
                        <Avatar
                            className="avatar-logo"
                            shape="square"
                            size={50}
                            style={{ margin: '16px auto', display: 'block' }}>
                            {prefix?.prefix}
                        </Avatar>

                    )}
                    action={
                        <Space direction="vertical">
                            <Button size="small" type="primary" onClick={handleAddHome} icon={<i className="fa-solid fa-cloud-arrow-down"></i>}>
                                ติดตั้ง
                            </Button>
                        </Space>
                    }
                />
                <Button className="ant-btn-primary" style={{ marginTop: 16 }} block onClick={handleChangePassword}>เปลี่ยนรหัสผ่าน</Button>
                <Link to={makePath('/logout')} style={{ marginTop: 16 }}>
                    <Button className="ant-btn-dark ant-btn-widget" block>ออกจากระบบ</Button>
                </Link>
            </Drawer>
            <ChangePassword isOpen={changePassword} onClose={() => setChangePassword(false)} />

            <ModalAddHome
                isOpen={openModalAddHome}
                onClose={() => setOpenModalAddHome(false)} />

            <ModalComponents
                title={titleModal}
                keyModal={keyModal}
                isOpen={openModal}
                onClose={() => setOpenModal(false)} />
        </>
    )
}

export default TopbarWidget
