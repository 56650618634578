import React, { FC } from 'react'
import { Member } from '../../@types/member'
import { getBankColor, getBankName, Banks } from '../../helpers/bank'
import WithdrawForm from './WithdrawForm'
import { format } from '../../helpers/number'
import { Col, Row, Statistic, Typography } from 'antd'

const { Text } = Typography

interface BankInfoWidgetProps {
	credit: number
	info: Member
}

const BankInfoWidget: FC<BankInfoWidgetProps> = ({ credit, info }) => (
	<Row justify="center" className="margin-form-card">
		<Col xs={24} md={16} xl={12}>
			<div className="form-card">
				<div className="form-card-body">
					<Text type="secondary" style={{ marginBottom: 6, display: 'block' }}>ถอนเงินเข้าบัญชีธนาคาร</Text>
					<div style={{ marginBottom: 24 }}>
						<div className="bank-card">
							<div
								className="bank-card-logo"
								style={{ boxShadow: `0 8px 12px -4px ${getBankColor(info.bankCode)}` }}
							>
								<img
									src={Banks.filter(bankName => bankName.key === info?.bankCode.toUpperCase())[0].image}
									alt={getBankName(info.bankCode)}
								/>
							</div>

							<div className="bank-card-info">
								<div><small>{getBankName(info.bankCode)}</small></div>
								<div className="bank-deposite-account">{info.bankNumber}</div>
								<div><small>{info.firstName} {info.lastName}</small></div>
							</div>
						</div>
					</div>
					<Row gutter={16} style={{ marginBottom: 24 }}>
						<Col span={12}>
							<Statistic title="จำนวนเงินที่ถอนได้" value={format(credit)} prefix="฿" />
						</Col>
						<Col span={12}>
							<Statistic title="ยอดถอนขั้นต่ำ" value={format(info.minWithdraw)} prefix="฿" />
						</Col>
					</Row>
					<WithdrawForm initAmount={credit} />
				</div>
			</div>
		</Col>
	</Row>
)

export default BankInfoWidget
