import React, { FC, MouseEvent, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Member } from '../../@types/member'
import { makePath } from '../../helpers/path'
import { useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import AuthService from '../../services/auth'
import { setUser } from '../../store/user/user.actions'
import { topbarText } from '../../constant'
import ModalComponents from '../../components/Modal/ModalComponents'

interface BottomBarProps {
	member: Member | undefined
}
const BottomBar: FC<BottomBarProps> = ({ member }) => {
	const history = useHistory()
	const loc = useLocation()
	const dispatch = useDispatch()

	const [titleModal, setTitleModal] = useState<string>("")
	const [keyModal, setKeyModal] = useState<string>("")

	const [openModal, setOpenModal] = useState<boolean>(false)
	const handleModal = () => setOpenModal(!openModal)

	const launchGame = (e: MouseEvent<any>) => {
		e.preventDefault()

		if (!member?.game.meta) {
			toast.error('กรุณาฝากเงินครั้งแรกเพื่อเข้าเล่นเกม')
			return
		}
		// history.push(makePath('/launch-game', [`t=${Date.now() + 5000}`]))
		history.push(makePath('/launch-game'))
	}

	const refetchCredit = async () => {
		try {
			const res = await AuthService.getMemberInfo(true)
			dispatch(setUser(res))
		} catch ({ message }: any) {
			toast.error(message as string)
		}
	}

	return (
		<>
			<div className="bottombar-mobile">
				<ul className="nav-menu">
					<li className="nav-item">
						<Link
							to={makePath('/wallet')}
							onClick={() => { refetchCredit() }}
							className={`nav-link ${loc.pathname === '/wallet' && 'active'}`}
						>
							<img src={`/icons/new/wallet.svg`} alt="icon" />
							<span>กระเป๋า</span>
						</Link>
					</li>
					<li className="nav-item">
						<Link
							to={"#"}
							onClick={() => {
								handleModal()
								setTitleModal(topbarText['/deposit'])
								setKeyModal("deposit")
							}}
							className={`nav-link ${(loc.pathname === '/deposit' || loc.pathname === '/deposit/bank') && 'active'}`}
						>
							<img src={`/icons/new/deposit.svg`} alt="icon" />
							<span>เติมเงิน</span>
						</Link>
					</li>
					<li className="nav-item middle-item">
						<a href="#game" className={`nav-link ${loc.pathname === '/launch-game' && 'active'}`} onClick={launchGame}>
							<div className="icon-button">
								<div className="icon-container">
									<img className="icon-center" src={`/icons/new/play.png`} alt="icon" />
								</div>
							</div>
							<span>เข้าเล่นเกม</span>
						</a>
					</li>
					<li className="nav-item">
						<Link
							to={"#"}
							onClick={() => {
								handleModal()
								setTitleModal(topbarText['/promotion'])
								setKeyModal("promotion")
							}}
							className={`nav-link ${loc.pathname === '/promotion' && 'active'}`}
						>
							<img src={`/icons/new/promotion.svg`} alt="icon" />
							<span>โปรโมชั่น</span>
						</Link>
					</li>
					<li className="nav-item">
						<Link
							to={"#"}
							onClick={() => {
								handleModal()
								setTitleModal(topbarText['/profile'])
								setKeyModal("profile")
							}}
							className={`nav-link ${loc.pathname === '/profile' && 'active'}`}
						>
							<img src={`/icons/new/account.svg`} alt="icon" />
							<span>โปรไฟล์</span>
						</Link>
					</li>
				</ul>
			</div>

			<ModalComponents
				title={titleModal}
				keyModal={keyModal}
				isOpen={openModal}
				onClose={() => setOpenModal(false)} />
		</>
	)
}

export default BottomBar
