import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
// import { makePath } from '../../helpers/path'
import ModalSupport from '../../components/Modal/ModalSupport'
import ModalComponents from '../../components/Modal/ModalComponents'
import { topbarText } from '../../constant'

interface WalletMobileMenuProps {
	lineLink: string
	wheel: boolean
	commission: boolean
	showMenuSupport?: boolean
	ranking?: boolean
	showModal?: string
}
const WalletMobileMenu: FC<WalletMobileMenuProps> = ({ lineLink, wheel, commission, showMenuSupport, ranking, showModal }) => {

	const [titleModal, setTitleModal] = useState<string>("")
	const [keyModal, setKeyModal] = useState<string>("")

	const [openSupport, setOpenSupport] = useState<boolean>(false)
	const handleModalSupport = () => setOpenSupport(!openSupport)

	const [openModal, setOpenModal] = useState<boolean>(false)
	const handleModal = () => setOpenModal(!openModal)

	useEffect(() => {
		if (showModal) {
			handleModal()
			setTitleModal(topbarText[`/${showModal}`])
			setKeyModal(showModal)
		}
	}, [showModal])

	return (
		<>
			<div className="menu-mobile-wrapper">
				<div className="menu-mobile-col">
					<Link to={"#"}
						onClick={() => {
							handleModal()
							setTitleModal(topbarText['/deposit'])
							setKeyModal("deposit")
						}}
						className="menu-mobile">
						<div className="menu-mobile-icon">
							<img src={`/icons/new/deposit.svg`} alt="icon" />
						</div>
						เติมเงิน
					</Link>
				</div>
				<div className="menu-mobile-col">
					<Link to={"#"}
						onClick={() => {
							handleModal()
							setTitleModal(topbarText['/withdraw'])
							setKeyModal("withdraw")
						}}
						className="menu-mobile">
						<div className="menu-mobile-icon">
							<img src={`/icons/new/withdraw.svg`} alt="icon" />
						</div>
						ถอนเงิน
					</Link>
				</div>
				<div className="menu-mobile-col">
					<Link to={"#"}
						onClick={() => {
							handleModal()
							setTitleModal(topbarText['/promotion'])
							setKeyModal("promotion")
						}}
						className="menu-mobile">
						<div className="menu-mobile-icon">
							<img src={`/icons/new/promotion.svg`} alt="icon" />
						</div>
						โปรโมชั่น
					</Link>
				</div>
				<div className="menu-mobile-col">
					<Link to={"#"}
						onClick={() => {
							handleModal()
							setTitleModal(topbarText['/transactions'])
							setKeyModal("transactions")
						}}
						className="menu-mobile">
						<div className="menu-mobile-icon">
							<img src={`/icons/new/history.svg`} alt="icon" />
						</div>
						ประวัติ
					</Link>
				</div>
				<div className="menu-mobile-col">
					<Link to={"#"}
						onClick={() => {
							handleModal()
							setTitleModal(topbarText['/profile'])
							setKeyModal("profile")
						}}
						className="menu-mobile">
						<div className="menu-mobile-icon">
							<img src={`/icons/new/account.svg`} alt="icon" />
						</div>
						โปรไฟล์
					</Link>
				</div>
				{commission ? (
					<div className="menu-mobile-col">
						<Link to={"#"}
							onClick={() => {
								handleModal()
								setTitleModal(topbarText['/commission'])
								setKeyModal("commission")
							}}
							className="menu-mobile">
							<div className="menu-mobile-icon">
								<img src={`/icons/new/affiliate.svg`} alt="icon" />
							</div>
							คอมมิชชั่น
						</Link>
					</div>
				) : (
					<div className="menu-mobile-col">
						<Link to={"#"}
							onClick={() => {
								handleModal()
								setTitleModal(topbarText['/affiliate'])
								setKeyModal("affiliate")
							}}
							className="menu-mobile">
							<div className="menu-mobile-icon">
								<img src={`/icons/new/affiliate.svg`} alt="icon" />
							</div>
							ชวนเพื่อน
						</Link>
					</div>
				)}

				{wheel && (
					<div className="menu-mobile-col">
						<Link to={"#"}
							onClick={() => {
								handleModal()
								setTitleModal(topbarText['/lucky-wheel'])
								setKeyModal("lucky-wheel")
							}}
							className="menu-mobile">
							<div className="menu-mobile-icon">
								<img src={`/icons/new/wheel.svg`} alt="wheel icon" />
							</div>
							กงล้อ
						</Link>
					</div>
				)}

				{!showMenuSupport && (
					<div className="menu-mobile-col">
						<Link to={"#"} onClick={handleModalSupport} className="menu-mobile">
							<div className="menu-mobile-icon">
								<img src={`/icons/new/help.svg`} alt="chat icon" />
							</div>
							แจ้งปัญหา
						</Link>
					</div>
				)}

				{lineLink && showMenuSupport && (
					<div className="menu-mobile-col">
						<a href={lineLink} target="_blank" rel="noreferrer" className="menu-mobile">
							<div className="menu-mobile-icon">
								<img src={`/icons/new/help.svg`} alt="icon" />
							</div>
							แจ้งปัญหา
						</a>
					</div>
				)}

				{ranking && (
					<div className="menu-mobile-col">
						<Link to={"#"}
							onClick={() => {
								handleModal()
								setTitleModal(topbarText['/ranking'])
								setKeyModal("ranking")
							}}
							className="menu-mobile">
							<div className="menu-mobile-icon">
								<img src={`/icons/new/ranking.svg`} alt="affiliate icon" />
							</div>
							จัดอันดับ
						</Link>
					</div>
				)}

			</div>

			<ModalSupport
				isOpen={openSupport}
				onClose={() => setOpenSupport(false)} />

			<ModalComponents
				title={titleModal}
				keyModal={keyModal}
				isOpen={openModal}
				onClose={() => setOpenModal(false)} />
		</>
	)
}

export default WalletMobileMenu
